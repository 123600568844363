import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import { Dialog, IconButton, InputAdornment, Stack } from "@mui/material";
import { DialogContent, TextField, Typography, styled } from "@mui/material";
import Toast from "../../../components/Toast";
import { singUp } from "../api";
import TailSpinLoader from "../../../components/TailSpinLoader";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { baseDomain, captchaKey } from "../../../repo/Repository";
import Repo from "../../../repo/Repo";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Domains } from "./DomainsList";

export const schema = yup
  .object({
    email: yup.string().email().required("*Email is required"),
    password: yup.string().required("*Password is required"),
    confirmPassword: yup
      .string()
      .required("*confirm password is required")
      .oneOf([yup.ref("password")], "Passwords do not match"),
  })
  .required();

const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "14px",
  color: "#353535",
});

function Signup({ open, onClose }) {
  const [state, setState] = useState({
    loading: false,
    error: false,
    showPass: false,
    showConfirm: false,
    recaptchaValue: "",
    check: false,
    step: 1, // Add step state
    userType: "Student", // Default value for userType
    domain: "Computer Science",
    email: "",
    otherDomain: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      userType: state.userType,
      domain: state.domain,
    },
  });

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (state.step === 1) {
          document.getElementById("nextBtn").click();
        } else if (state.step === 2) {
          document.getElementById("btn").click();
        }
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [state.step]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const handleCaptcha = (value) => {
    setState((prev) => ({ ...prev, recaptchaValue: value, check: false }));
  };
  const handleNext = () => {
    setState((prev) => ({ ...prev, step: prev.step + 1 }));
  };

  const handleShow = () => setShowPassword((show) => !show);

  const onSubmit = async (arg) => {
    setState((prev) => ({ ...prev, check: true }));

    if (state.recaptchaValue.length !== 0) {
      setState((prev) => ({ ...prev, loading: true, check: true }));
      if (!state.userType) {
        Toast("info", "Please confirm your profession");
        return;
      }
      // if (state.domain != "Other" && !state.domain) {
      //   Toast("info", "Enter your domain");
      //   return;
      // }
      // if (state.domain == "Other" && !state.otherDomain) {
      //   Toast("info", "Enter your domain");
      //   return;
      // }
      let domain = state.domain;
      let res = await singUp(arg.email, arg.password, domain, state.userType);

      console.log("res.response.data.userId:", res.response.data);
      if (res.response.data.userId) {
        await axios
          .post(`${baseDomain}/email`, {
            request: {
              method: "sendVerificationLink",
              data: {
                to: arg.email,
              },
            },
          })
          .catch(async (err) => {});

        Toast(
          "success",
          "Verification email  sent. Please check your inbox or junk/spam folder"
        );
        onClose();
      } else if (res.response.status.statusCode === 409) {
        Toast("error", "Email Already Exists");
        setState((prev) => ({ ...prev, loading: false }));
      } else {
        Toast("error", "Error");
        setState((prev) => ({ ...prev, loading: false }));
      }
    }
  };

  const handleBack = () => {
    setState((prev) => ({ ...prev, step: prev.step - 1 }));
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <Stack spacing={2.4}>
          {state.step === 1 && (
            <>
              <Stack spacing={0.8}>
                <Text sx={{ fontWeight: 600 }}>Email</Text>
                <TextField
                  fullWidth
                  size="small"
                  sx={{
                    background: "#F0F0F0",
                    border: "none",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                  }}
                  placeholder="example@email.com"
                  {...register("email")}
                />
                <Typography sx={{ color: "red" }}>
                  {errors.email?.message}
                </Typography>
              </Stack>
              <Stack spacing={0.8}>
                <Text sx={{ fontWeight: 600 }}>Password</Text>

                <OutlinedInput
                  fullWidth
                  size="small"
                  sx={{
                    background: "#F0F0F0",
                    border: "none",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                  }}
                  placeholder="********"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShow}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...register("password")}
                />
                <Typography sx={{ color: "red" }}>
                  {errors.password?.message}
                </Typography>
              </Stack>
              <Stack spacing={0.8}>
                <Text sx={{ fontWeight: 600 }}>Confirm Password</Text>

                <OutlinedInput
                  fullWidth
                  size="small"
                  sx={{
                    background: "#F0F0F0",
                    border: "none",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                  }}
                  placeholder="********"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShow}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...register("confirmPassword")}
                />
                <Typography sx={{ color: "red" }}>
                  {errors.confirmPassword?.message}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                width={"100%"}
                justifyContent={"space-evenly"}
                spacing={2}
              >
                <Button
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#353535",
                    background: "#ffffff !important",
                    border: "1px solid #353535",
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  id="nextBtn"
                  variant="contained"
                  onClick={handleSubmit(handleNext)}
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    fontSize: "16px",
                    background: "linear-gradient(90deg, #5671F1, #17CEAD)",
                    "&:hover": {
                      background: "linear-gradient(90deg, #3046AE, #14B599)",
                    },
                  }}
                  //disable the button if fields are empty
                >
                  Next
                </Button>
              </Stack>
            </>
          )}
          {state.step === 2 && (
            <Stack spacing={2}>
              <Stack>
                <Text sx={{ fontWeight: 600 }}>
                  Please confirm your profession
                </Text>
                <Select
                  fullWidth
                  size="small"
                  sx={{
                    background: "#F0F0F0",
                    border: "none",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                  }}
                  name="userType"
                  value={state.userType}
                  onChange={handleInput}
                >
                  <MenuItem value="Student">Student</MenuItem>
                  <MenuItem value="Researcher">Researcher</MenuItem>
                  <MenuItem value="Professional">
                    Industry Professional
                  </MenuItem>
                </Select>
              </Stack>
              <Stack>
                <Text sx={{ fontWeight: 600 }}>
                  Please confirm your research focus:
                </Text>
                <Select
                  fullWidth
                  size="small"
                  sx={{
                    background: "#F0F0F0",
                    border: "none",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                  }}
                  name="domain"
                  value={state.domain}
                  onChange={handleInput}
                >
                  {Domains.map((domain) => (
                    <MenuItem value={domain}>
                      <Stack
                        direction="row"
                        width="100%"
                        justifyContent="space-between"
                      >
                        <Typography>{domain}</Typography>
                        {(domain == "Computer Science" ||
                          domain == "Medical & Health Sciences" ||
                          domain == "Humanities and Social Sciences" ||
                          domain == "Business Studies") && (
                          <Tooltip
                            title={
                              <Typography>
                                Currently our automated Literature Review and
                                Search is optimized for this domain.
                              </Typography>
                            }
                            placement="top"
                          >
                            <InfoOutlinedIcon
                              sx={{ color: "#353535", mr: 1 }}
                            />
                          </Tooltip>
                        )}
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              {/* {state.domain == "Other" && (
                <Stack>
                  <Text sx={{ fontWeight: 600 }}>Other Domain</Text>
                  <TextField
                    fullWidth
                    size="small"
                    sx={{
                      background: "#F0F0F0",
                      border: "none",
                      borderRadius: "4px",
                      "& fieldset": { border: "none" },
                    }}
                    name="otherDomain"
                    value={state.otherDomain}
                    onChange={handleInput}
                  />
                </Stack>
              )} */}
              <Stack width="100%">
                {state.loading ? (
                  <Box sx={{ height: "50px" }}>
                    <TailSpinLoader />
                  </Box>
                ) : (
                  <Box>
                    <Stack width="100%">
                      <ReCAPTCHA
                        sitekey={captchaKey}
                        // sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"} //test key
                        onChange={handleCaptcha}
                        style={{ width: "100%" }}
                      />
                      {state.check && (
                        <p style={{ color: "red" }}>*Please Verify</p>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent={"space-evenly"}
                      mt={2}
                      spacing={2}
                    >
                      <Button
                        sx={{
                          width: "100%",
                          textTransform: "none",
                          fontFamily: "Raleway",
                          fontWeight: 600,
                          fontSize: "16px",
                          color: "#353535",
                          background: "#ffffff !important",
                          border: "1px solid #353535",
                        }}
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                      <Button
                        id="btn"
                        variant="contained"
                        sx={{
                          width: "100%",
                          textTransform: "none",
                          fontFamily: "Raleway",
                          fontWeight: 600,
                          fontSize: "16px",
                          background:
                            "linear-gradient(90deg, #5671F1, #17CEAD)",
                          "&:hover": {
                            background:
                              "linear-gradient(90deg, #3046AE, #14B599)",
                          },
                        }}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Done
                      </Button>
                    </Stack>
                  </Box>
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default Signup;
