import React from "react";
import {
  Box,
  Typography,
  Stack,
  Link,
  IconButton,
  Tooltip,
} from "@mui/material";
import { BtnSave } from "./styles";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LanguageIcon from "@mui/icons-material/Language";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { TailSpin } from "react-loader-spinner";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { Check } from "@mui/icons-material";

const SemanticContent = ({
  item,
  isSmall,
  goBack,
  goBackToPreviousItem,
  saveLoading,
  selectedDoi,
  semanticSaveStatus,
  savePaper,
  tooltipOpen,
  handleCopy,
  hasNavigationHistory,
}) => {
  const key = item.doi && item.doi !== "None" ? item.doi : item.title;
  const isDoiNone = item.doi === "None";
  return (
    <Box
      sx={{
        width: "100%",
        background: "rgba(246, 246, 246, 0.7)",
        // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        borderRadius: "4px",
        // p: 3,
        display: "flex",
        height: isSmall ? "auto" : "75vh",
        flexDirection: isSmall ? "column" : "row",
        // overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: isSmall ? "100%" : "40%",
          // height: "70vh",
          borderRight: isSmall ? "none" : "1px solid #DEE0E3",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          px: 3,
          py: 3,
          // overflowY: "auto",
        }}
      >
        {/* <Link
        sx={{
          textDecoration: "none",
          cursor: "pointer",
          border: "1px solid #353535",
          "&:hover": {
            borderColor: "#353535CC",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          },
          borderRadius: "4px",
          py: 0.6,
          px: 1,
        }}
        href={item.url}
        target="_blank"
      > */}

        <Stack
          direction={{ xs: "column", sm: "row", md: "column", lg: "row" }}
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <BtnSave
            sx={{
              "&:hover": {
                borderColor: "#353535CC",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={goBack}
          >
            <Stack direction="row" spacing="4px" alignItems="center">
              <MapsHomeWorkIcon
                style={{
                  height: "20px",
                  color: "#35353599",
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#353535CC",
                }}
              >
                Back to Search
              </Typography>
            </Stack>
          </BtnSave>

          {hasNavigationHistory() && (
            <BtnSave
              sx={{
                "&:hover": {
                  borderColor: "#353535CC",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                },
                mt: { xs: 2, sm: 0, md: 2, lg: 0 },
              }}
              onClick={goBackToPreviousItem}
            >
              <Stack direction="row" spacing="4px" alignItems="center">
                <ArrowBackIcon
                  style={{
                    height: "20px",
                    color: "#35353599",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#353535CC",
                  }}
                >
                  Previous page
                </Typography>
              </Stack>
            </BtnSave>
          )}
        </Stack>

        <Box sx={{ overflowY: "auto" }}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#353535",
              py: 1,
              fontFamily: "Raleway",
            }}
          >
            {item.title}
          </Typography>
          <Box sx={{ py: 1 }}>
            <Stack spacing="8px" direction="row" alignItems="center">
              <GroupOutlinedIcon sx={{ height: "15px", color: "#35353599" }} />
              <Typography
                sx={{
                  color: "#35353599",
                  fontSize: "13px",
                  fontFamily: "Raleway",
                }}
              >
                {item.author ? item.author : "N/A"}
              </Typography>
            </Stack>
            <Stack
              spacing="8px"
              direction="row"
              alignItems="center"
              sx={{ py: 1 }}
            >
              <AutoStoriesOutlinedIcon
                sx={{ height: "15px", color: "#35353599" }}
              />
              <Typography
                sx={{
                  color: "#35353599",
                  fontSize: "13px",
                  fontFamily: "Raleway",
                }}
              >
                {item.journal ? item.journal : "N/A"}
              </Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <CalendarMonthIcon sx={{ height: "15px", color: "#35353599" }} />
              <Typography
                sx={{
                  color: "#35353599",
                  fontSize: "13px",
                  fontFamily: "Raleway",
                }}
              >
                {item.year}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "12px",
              py: 2,
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Tooltip
              title={
                isDoiNone
                  ? "It seems like a review paper. Hence can't be saved"
                  : ""
              }
              arrow
              disableHoverListener={!isDoiNone}
            >
              <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
                <BtnSave
                  variant="outlined"
                  startIcon={
                    saveLoading[key] && selectedDoi[key] ? (
                      <TailSpin
                        height="15"
                        width="15"
                        color="#5AB7BF"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : semanticSaveStatus[key] ? (
                      <Check
                        size="small"
                        sx={{
                          fontSize: "14px",
                          mr: 1,
                          color: "green",
                        }}
                      />
                    ) : (
                      <BookmarkBorderIcon
                        sx={{ height: "25px", color: "#828282" }}
                      />
                    )
                  }
                  sx={{
                    py: 2,
                    fontSize: "14px",
                    "&:hover": {
                      borderColor: "#353535CC",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                    },
                    fontWeight: "700",
                    width: { xs: "100%", sm: "auto" },
                    color: semanticSaveStatus[key]
                      ? "green !important"
                      : "#353535CC",
                  }}
                  disabled={isDoiNone || semanticSaveStatus[key]}
                  onClick={() => savePaper(item)}
                >
                  {saveLoading[key] && selectedDoi[key]
                    ? "Saving..."
                    : semanticSaveStatus[key]
                    ? "Saved"
                    : "Save"}
                </BtnSave>
              </Box>
            </Tooltip>

            <Link
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                border: "1px solid #353535",
                "&:hover": {
                  borderColor: "#353535CC",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                },
                width: { xs: "100%", sm: "auto" },
                borderRadius: "4px",

                py: 0.6,
                px: 1,
              }}
              href={item.url}
              target="_blank"
            >
              <Stack
                direction="row"
                spacing="4px"
                alignItems="center"
                justifyContent="center"
              >
                <LanguageIcon
                  style={{
                    height: "20px",
                    color: "#35353599",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#353535CC",
                  }}
                >
                  Source
                </Typography>
              </Stack>
            </Link>
            {item.openAccessLink ? (
              <Link
                sx={{
                  textDecoration: "none",
                  cursor: "pointer",
                  border: "1px solid #353535",
                  "&:hover": {
                    borderColor: "#353535CC",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  },
                  width: { xs: "100%", sm: "auto" },
                  borderRadius: "4px",
                  py: 0.6,
                  px: 1,
                }}
                href={item.openAccessLink}
                target="_blank"
              >
                <Stack
                  direction="row"
                  spacing="4px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <PictureAsPdfIcon
                    style={{ height: "20px", color: "#35353599" }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#353535CC",
                    }}
                  >
                    View PDF
                  </Typography>
                </Stack>
              </Link>
            ) : null}
          </Box>
          <hr />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "center", sm: "space-between" },
              alignItems: "center",
              flexWrap: "wrap",
              py: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#353535",
                fontFamily: "Raleway",
              }}
            >
              Citations
            </Typography>

            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "15px",
                  color: "#35353599",
                  fontFamily: "Raleway",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    fontSize: "15px",
                    color: "#353535",
                    fontWeight: 700,
                  }}
                >
                  {item.influentialCitationCount}{" "}
                </Typography>
                Influential Citations
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontSize: "15px",
                  color: "#35353599",
                  fontFamily: "Raleway",
                  textAlign: { xs: "center", sm: "unset" },
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    fontSize: "15px",
                    color: "#353535",
                    fontWeight: 700,
                    fontFamily: "Raleway",
                  }}
                >
                  {item.citationCount}{" "}
                </Typography>
                Citations
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "center", sm: "space-between" },
              flexWrap: "wrap",
              alignItems: "center",
              py: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#353535",
                fontFamily: "Raleway",
              }}
            >
              References
            </Typography>

            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "15px",
                  color: "#35353599",
                  fontFamily: "Raleway",
                  // pr: isSmall ? 0 : 6,
                  pr: { xs: 0, sm: item.referenceCount > 10 ? 6 : 7 },
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    fontSize: "15px",
                    color: "#353535",
                    fontWeight: 700,
                    fontFamily: "Raleway",
                    textAlign: { xs: "center", md: "unset" },
                  }}
                >
                  {item.referenceCount}{" "}
                </Typography>
                References
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: isSmall ? "100%" : "60%",
          px: 3,
          py: 3,
          borderTop: isSmall ? "1px solid #DEE0E3" : "none",
        }}
      >
        {item.tldr && item.tldr !== "None" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              overflowX: "auto",
              px: 2,
              py: 2,
              background: "#EDEDED",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                pr: 3,
                alignItems: "start",
              }}
            >
              <AutoAwesomeIcon sx={{ height: "20px", color: "#35353599" }} />
              <Typography
                variant="body1"
                sx={{ fontFamily: "Raleway", fontSize: "14px" }}
              >
                <Typography variant="span" sx={{ fontWeight: "600" }}>
                  Summary:{" "}
                </Typography>
                {item.tldr}
              </Typography>
            </Box>
            <Box>
              <Tooltip
                open={tooltipOpen}
                title="Text Copied!"
                placement="top"
                arrow
              >
                <IconButton aria-label="delete" onClick={handleCopy}>
                  <ContentCopyIcon sx={{ alignSelf: "start" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        ) : null}

        <Box sx={{ py: 3 }}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              color: "#353535",
              fontFamily: "Raleway",
            }}
          >
            Abstract
          </Typography>
          <Typography
            variant="body1"
            sx={{
              overflowY: "auto",
              height: "33vh",
              py: 1,
              fontSize: "15px",
              color: "#35353599",
              fontFamily: "Raleway",
            }}
          >
            {item.abstracts}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SemanticContent;
