import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Repo from "../../../repo/Repo";
import Toast from "../../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { getUser, userAdd, userLogged } from "../../Admin/userSlice";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { TextField, Stack, Button, styled } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import gIcon from "../../../assets/images/G.png";
import TailSpinLoader from "../../../components/TailSpinLoader";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { baseDomain } from "../../../repo/Repository";
const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "14px",
  color: "#353535",
});

export const schema = yup
  .object({
    email: yup.string().required("Email is required").trim(),
    password: yup.string().required("Password is required").trim(),
  })
  .required();

function Login({ open, onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [email, setEmail] = useState("");
  const [state, setState] = useState({
    email: "",
    password: "",
    loading: false,
    error: false,
    showPass: false,
  });
  const [verifyMail, setVerifyMail] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("btn").click();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const toggle = (type) => {
    if (state.loading) {
      return;
    }
    onClose(type);
  };

  const onSubmit = async (info) => {
    setEmail(info.email);
    try {
      setState((prev) => ({ ...prev, loading: true }));
      let { data } = await Repo.user({
        request: {
          method: "loginUser",
          data: {
            email: info.email,
            password: info.password,
          },
        },
      });
      setState((prev) => ({ ...prev, loading: false }));

      if (data.response?.status?.statusCode == 200) {
        let user = data?.response?.data?.userObj;
        if (user) {
          localStorage.setItem("token", user?.token);
          localStorage.setItem("email", user?._id);
          localStorage.setItem("userId", user?.zoteroUserId);
          localStorage.setItem("zoteroKey", user?.zoteroKey);

          delete user.token;

          // if (data.response.data.userObj?.allowUserAccess === true) {
          localStorage.setItem("loggedIn", true);
          dispatch(userAdd(user));
          dispatch(userLogged("user"));
          navigate("/projects");
          localStorage.setItem("refresh", true);
          // }
          //  else {
          //   navigate("/access-denied");
          // }
        }
      } else {
        setVerifyMail(
          data.response?.data?.error === "Email not verified!" ? true : false
        );
        Toast("error", data.response?.data?.error);
      }
    } catch (err) {
      setState((prev) => ({ ...prev, loading: false }));
      console.log("err:", err);
      Toast("error", "Error");
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);
      localStorage.setItem("email", userInfo?.email);
      setState((prev) => ({ ...prev, loading: true }));
      let { data } = await Repo.user({
        request: {
          method: "getUser",
          data: {
            _id: userInfo?.email,
            googleLogin: true,
          },
        },
      });
      localStorage.setItem("userId", data.response.data.userObj.zoteroUserId);
      localStorage.setItem("zoteroKey", data.response.data.userObj.zoteroKey);
      localStorage.setItem("token", data.response.data.userObj?.token);
      // if (data.response.data.userObj?.allowUserAccess === true) {
      localStorage.setItem("loggedIn", true);
      dispatch(userAdd(data.response.data.userObj));
      dispatch(userLogged("user"));
      navigate("/projects");
      localStorage.setItem("refresh", true);
      setState((prev) => ({ ...prev, loading: false }));
      // } else {
      //   navigate("/access-denied");
      // }
      // dispatch(getUser(true));
    },
    onError: () => {
      Toast("error", "Login Failed");
    },
  });
  const handleResendEmail = async () => {
    // if (user?.emailVerified) {
    setState((prev) => ({ ...prev, loading: true }));
    await axios
      .post(`${baseDomain}/email`, {
        request: {
          method: "sendVerificationLink",
          data: {
            to: email,
          },
        },
      })
      .catch(async (err) => {});
    setState((prev) => ({ ...prev, loading: false }));
    Toast(
      "success",
      "Verification email  sent. Please check your inbox or junk/spam folder"
    );
    // }
  };

  return (
    <Dialog
      open={open}
      onClose={() => toggle("loginModal")}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Stack spacing={2.4}>
          <Stack spacing={0.8}>
            <Text sx={{ fontWeight: 600 }}>Email</Text>
            <TextField
              fullWidth
              size="small"
              sx={{
                background: "#F0F0F0",
                border: "none",
                borderRadius: "4px",
                "& fieldset": { border: "none" },
              }}
              placeholder="example@email.com"
              {...register("email")}
            />
            <Typography sx={{ color: "red" }}>
              {errors.email?.message}
            </Typography>
          </Stack>
          <Stack spacing={0.8}>
            <Stack direction="row" width="100%" justifyContent="space-between">
              <Text sx={{ fontWeight: 600 }}>Password</Text>
              <Text
                sx={{ color: "#4285F4", cursor: "pointer" }}
                onClick={() => toggle("forgotModal")}
              >
                Forgot Password
              </Text>
            </Stack>
            <TextField
              fullWidth
              size="small"
              type="password"
              sx={{
                background: "#F0F0F0",
                border: "none",
                borderRadius: "4px",
                "& fieldset": { border: "none" },
              }}
              placeholder="********"
              {...register("password")}
            />
            <Typography sx={{ color: "red" }}>
              {errors.password?.message}
            </Typography>
            {verifyMail && (
              <Button
                variant="text"
                sx={{
                  textTransform: "none",
                  color: "#4285F4",
                  p: "0px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
                onClick={handleResendEmail}
              >
                Resend Verification Email
              </Button>
            )}
          </Stack>

          <Box sx={{ height: "40px" }}>
            {state.loading ? (
              <TailSpinLoader />
            ) : (
              <Button
                fullWidth
                id="btn"
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "linear-gradient(90deg, #5671F1, #17CEAD)",
                  "&:hover": {
                    background: "linear-gradient(90deg, #3046AE, #14B599)",
                  },
                }}
                onClick={handleSubmit(onSubmit)}
              >
                Login
              </Button>
            )}
          </Box>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Text>Don’t have account?</Text>
            <Text
              sx={{ color: "#4285F4", cursor: "pointer" }}
              onClick={() => toggle("signupModal")}
            >
              Create new account
            </Text>
          </Stack>
          <Text sx={{ textAlign: "center" }}>or</Text>

          <Button
            variant="contained"
            fullWidth
            sx={{
              background: "linear-gradient(90deg, #83E1B4, #F0D795)",
              "&:hover": {
                background: "linear-gradient(90deg, #6DBB95, #D8B573)",
              },
              p: "1px 10px 1px 5px",
              textTransform: "none",
              fontFamily: "Raleway",
              fontWeight: 600,
            }}
            onClick={googleLogin}
          >
            <img style={{ marginRight: "10px" }} src={gIcon} alt="" />
            <Text sx={{ fontWeight: 600 }}> Sign in with Google</Text>
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default Login;
