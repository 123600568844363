import Repo from "../../../repo/Repo";

export const singUp = async (email, password, domain, userType) => {
  let { data } = await Repo.user({
    request: {
      method: "signupUser",
      data: {
        email: email,
        password: password,
        domain: domain,
        userType: userType,
      },
    },
  });
  return data;
};
