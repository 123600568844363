import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "../../Analytics";
import ToolsSection from "./ToolsSection";
import SubscribeSection from "./SubscribeSection";
import FAQs from "./FAQs";
import HomeSection from "./HomeSection";
import { Box, Button, Stack, Typography } from "@mui/material";
import { scroller } from "react-scroll";
import Footer from "../../layout/footer";
import JoinUS from "./JoinUS";
import Alert from "../../components/Dialogue";
import Login from "../authentication/login";
import Contact from "../subscription/Contact";
import AcceptCookiesForm from "../cookies";
import { useCookies } from "react-cookie";
import ListSlider from "./ListSlider";
import Testimonials from "./Testimonials";
import Pricing from "./pricing";
import NewCards from "../subscription/new cards";
import { ArrowUpward } from "@mui/icons-material";
import LandingHeader from "../../layout/header";
import hero_bg from "../../assets/images/NewImages/hero_bg.svg";
import ForgotPassword from "../Admin/ForgotPassword";
import Signup from "../authentication/sign up";

const LandingPage = () => {
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [cookies] = useCookies(["user"]);
  const [showScroll, setShowScroll] = useState(false);
  const [state, setState] = useState({
    alert: false,
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
    contactUsModal: false,
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Landing Page",
    });
  }, []);

  useEffect(() => {
    if (location?.state?.open) {
      setState((prev) => ({ ...prev, alert: true }));
    }
    const timeId = setTimeout(() => {
      setState((prev) => ({ ...prev, alert: false }));
    }, 10000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  const toggle = (name) => {
    console.log("name:", name);
    if (name === "enterprise") {
      setState((prev) => ({
        ...prev,
        [name]: !prev[name],
        contactUsModal: true,
      }));
    } else if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else if (name === "standard" || name === "pro") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: true }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };
  const handleContactModal = (arg) => {
    setContactModal((prev) => !prev);
  };

  const handleScroll = (name) => {
    console.log("name:", name);
    scroller.scrollTo([name], {
      duration: 2000,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  const closeAlert = () => {
    setState((prev) => ({ ...prev, alert: false }));
  };
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, []);
  return (
    <Box
      sx={{
        backgroundImage: `url(${hero_bg})`,
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <LandingHeader onClick={(e) => toggle(e)} />
      </Box>

      <HomeSection onSuccess={(e) => handleScroll(e)} />

      {/* <Box sx={{ height: "100px" }}></Box> */}
      <Box>
        <ToolsSection />
      </Box>
      <JoinUS onSuccess={(e) => handleScroll(e)} />
      <Box>
        <Testimonials />
      </Box>
      <Box sx={{ height: "auto", mb: 4 }}>
        <NewCards onSelect={(e) => toggle(e)} />
        {/* <SubscribeSection handleLogin={toggle} /> */}
      </Box>
      <Box>
        <FAQs />
      </Box>
      <Footer />
      {state.loginModal && <Login open={state.loginModal} onClose={toggle} />}
      {state.contactUsModal && !state.loginModal && (
        <Contact open={state.contactUsModal} onClose={handleContactModal} />
      )}

      {state.loginModal && (
        <Login open={state.loginModal} onClose={(e) => toggle(e)} />
      )}
      {state.forgotModal && (
        <ForgotPassword
          open={state.forgotModal}
          onClose={() => toggle("forgotModal")}
        />
      )}
      {state.signupModal && (
        <Signup
          open={state.signupModal}
          onClose={() => toggle("signupModal")}
        />
      )}
      {!cookies.user && (
        <Box
          sx={{
            position: "fixed",
            bottom: "10px",
            right: "10px",
            left: "10px",
          }}
        >
          <AcceptCookiesForm />
        </Box>
      )}
      {showScroll && (
        <Button
          onClick={scrollTop}
          variant="contained"
          color="primary"
          sx={{
            position: "fixed",
            bottom: "40px",
            right: "40px",
            zIndex: 1000,
            backgroundColor: "#353535",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#5f5f5f",
            },
          }}
        >
          <ArrowUpward />
        </Button>
      )}
    </Box>
  );
};

export default LandingPage;
