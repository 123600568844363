import { useContext, useEffect, useMemo, useState } from "react";

import { Editor, useEditor } from "@tiptap/react";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { TiptapCollabProvider, WebSocketStatus } from "@hocuspocus/provider";
import EditorContext from "../context/EditorContext";
import { userColors, userNames } from "../lib/constants";
import { randomElement } from "../lib/utils";
import { useSidebar } from "./useSidebar";
import Collaboration from "@tiptap/extension-collaboration";
import ExtensionKit from "../extensions/extensions-kits";
import { initialContent } from "../lib/data/InitialContent";

const TIPTAP_AI_APP_ID = process.env.NEXT_PUBLIC_TIPTAP_AI_APP_ID || "q9g4o79g";
const TIPTAP_AI_BASE_URL =
  process.env.NEXT_PUBLIC_TIPTAP_AI_BASE_URL || "https://api.tiptap.dev/v1/ai";

export const useBlockEditor = ({ provider }) => {
  const leftSidebar = useSidebar();

  const editor = useEditor({
    autofocus: true,
    onCreate: ({ editor }) => {
      provider?.on("synced", () => {
        if (editor.isEmpty) {
          editor.commands.setContent(initialContent);
        }
      });
    },
    extensions: [
      ...ExtensionKit({
        provider,
      }),
      // Collaboration.configure({
      //   document: ydoc,
      // }),
      // CollaborationCursor.configure({
      //   provider,
      //   user: {
      //     name: randomElement(userNames),
      //     color: randomElement(userColors),
      //   },
      // }),
      // Ai.configure({
      //   appId: TIPTAP_AI_APP_ID,
      //   token: aiToken,
      //   baseUrl: TIPTAP_AI_BASE_URL,
      //   autocompletion: true,
      //   onLoading: () => {
      //     setIsAiLoading(true);
      //     setAiError(null);
      //   },
      //   onSuccess: () => {
      //     setIsAiLoading(false);
      //     setAiError(null);
      //   },
      //   onError: (error) => {
      //     setIsAiLoading(false);
      //     setAiError(error.message);
      //   },
      // }),
    ],
    editorProps: {
      attributes: {
        autocomplete: "off",
        autocorrect: "off",
        autocapitalize: "off",
        // class: "min-h-full",
      },
    },
  });

  const characterCount = editor?.storage.characterCount || {
    characters: () => 0,
    words: () => 0,
  };

  // useEffect(() => {
  //   provider?.on("status", (event) => {
  //     setCollabState(event.status);
  //   });
  // }, [provider]);

  return { editor, characterCount, leftSidebar };
};
