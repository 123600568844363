import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Toast from "../../components/Toast";
import { baseDomain } from "../../repo/Repository";

let abortController;
const toastShownInitialState = JSON.parse(localStorage.getItem("toastShown")) || false;

const initialState = {
  loading: false,
  error: "",
  progress: 0,
  interval: 500,
  myTimer: false,
  projects: [],
  projectTitle: "",
  pasteContent: "",
  tracker: 0,
  isSessionExpired: false,
  domain: "",
  selectedFormat: "short",
  value: "web",
  toastShown: toastShownInitialState,
  // abortController: null,
};

// export const getReview = createAsyncThunk("review/fetchReview", (arg) => {
//   const { info, value } = arg;

//   if (value === "web") {
//     const axiosConfig = {
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "http://localhost:3000",
//         "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
//       },
//     };

//     return axios
//       .post(`${baseDomain}/script`, info, axiosConfig)
//       .then(({ data }) => data)
//       .catch((error) => {
//         console.log("error");
//       });
//   } else {
//     const axiosConfig = {
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "http://localhost:3000",
//         "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
//       },
//     };

//     return axios
//       .post(`${baseDomain}/script`, info, axiosConfig)
//       .then(({ data }) => data)
//       .catch((error) => {
//         console.log("error");
//       });
//   }
// });



// export const getReview = createAsyncThunk("review/fetchReview", async (arg, { signal }) => {
//   const { info, value } = arg;

//   abortController = new AbortController();

//   const axiosConfig = {
//     headers: {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "http://localhost:3000",
//       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
//     },
//     signal: abortController.signal,
//   };

//   try {
//     const response = await axios.post(`${baseDomain}/script`, info, axiosConfig);
//     return response.data;
//   } catch (error) {
//     if (axios.isCancel(error)) {
//       console.log("Request canceled", error.message);
//     } else {
//       console.log("error");
//     }
//   }
// });

export const getReview = createAsyncThunk("review/fetchReview", async (arg, { rejectWithValue }) => {
  const { info, value } = arg;

  // Initialize the abort controller
  abortController = new AbortController();
  // const { signal } = abortController;

  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    },
    
    signal:abortController.signal,
  };

  try {
    const response = await axios.post(`${baseDomain}/script`, info, axiosConfig);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      // Handle the cancel request
      return rejectWithValue("Request canceled");
    } else {
      // Handle other errors
      return rejectWithValue(error.response.data);
    }
  }
});


export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    addReview: (state, action) => {
      // state.review = action.payload;
    },
    handleSession: (state, action) => {
      state.isSessionExpired = false;
    },
    showToast: (state) => {
      state.toastShown = true;
      localStorage.setItem("toastShown", true);
    },
    hideToast: (state) => {
      state.toastShown = false;
      localStorage.setItem("toastShown", false);
    },
    addProject: (state, action) => {
      let project = {
        title: action.payload,
        literature: "",
        sourceArr: [],
        query: "",
        sourceObj: {},
        lastString: "",
        libraryReferences: [],
      };
      state.projectTitle = action.payload;
      state.projects.push(project);
    },
    setLitDomain: (state, action) => {
      state.domain = action.payload;
    },
    clearLitDomain: (state) =>{
      state.domain = initialState.domain
    },
    setSelectedFormat: (state, action) => {
      state.selectedFormat = action.payload;
    },
    clearSelectedFormat: (state) => {
      state.selectedFormat = initialState.selectedFormat;
    },
    setValue: (state, action) => {
      state.value = action.payload;
    },
    clearValue: (state) => {
      state.value = initialState.value;
    },
    addProjectTitle: (state, action) => {
      state.projectTitle = action.payload;
    },
    setPasteContent: (state, action) => {
      state.pasteContent = action.payload;
    },
    addQuery: (state, action) => {
      const { value, title } = action.payload;
      let found = state.projects.find((i) => i.title == title);
      if (found) {
        found.query = value;
      } else {
        Toast("info", "Project Title not found");
      }
    },
    addProgress: (state) => {
      if (state.progress < 100) {
        state.progress += 1;
        state.tracker += 1;
      } else {
        state.progress = 1;
      }
    },
    clearQuery: (state, action) => {
      let found = state.projects.find((i) => i.title == action.payload);
      if (found) {
        found.query = "";
      } else {
        Toast("info", "Project Title not found");
      }
    },
    clrTracker: (state) => {
      state.tracker = 0;
    },
    clrLoading: (state) => {
      state.loading = false;
    },
    clearLitReview: (state, action) => {
      state.myTimer = false;
      state.loading = false;
      state.progress = 0;
      state.tracker = 0;
      state.interval = 500;
      state.projectTitle = "";
      let found = state.projects.find((i) => i.title == action.payload);
      if (found) {
        found.sourceArr = [];
        found.literature = "";
        found.sourceObj = {};
        found.lastString = "";
        found.libraryReferences = [];
      } else {
        Toast("info", "Project Title not found");
      }
    },
    clearLiterature: (state, action) => {
      state.projectTitle = "";
      let found = state.projects.find((i) => i.title == action.payload);
      if (found) {
        found.literature = "";
      } else {
        Toast("info", "Project Title not found");
      }
    },
    clearLitLoading:(state)=>{
      state.loading = initialState.loading
    },
    clearAllLitReview: (state) => {
      return initialState;
    },
    
    
    abortCancelFetch: (state) => {
      // Abort the fetch
      if (abortController){ abortController.abort()};
    },
  
  },
  abortReviewFetch: (state) => {
    if (abortController) {
      abortController.abort();
    }
  },
 

  
  extraReducers: (builder) => {
    builder.addCase(getReview.pending, (state) => {
      state.loading = true;
      state.myTimer = true;
      let found = state.projects.find((i) => i.title == state.projectTitle);
      if (found) {
        found.literature = "";
        found.sourceArr = [];
        found.sourceObj = {};
        found.lastString = "";
        found.libraryReferences = [];
      }
    });
    builder.addCase(getReview.fulfilled, (state, action) => {
      state.myTimer = false;
      state.loading = false;
      state.error = "";
      state.progress = 0;
      state.interval = 500;

      if (action.payload?.data) {
        const { data } = action.payload;
        if (
          data.error ===
            "Invalid Session Id! You might be logged in from two different devices" ||
          data.error == "Token is empty!"
        ) {
          Toast("info", "Session has expired login again");
          state.isSessionExpired = true;
        } else if (data.response == "Something went wrong!") {
          Toast("error", "Something went wrong!");
        } else {
          let found = state.projects.find((i) => i.title == state.projectTitle);
          if (found) {
            if (
              action.payload.data.error &&
              action.payload.data.response === ""
            ) {
              Toast("error", action.payload.data.error);
              state.loading = false;
              state.myTimer = false;
              state.error = action.payload.data.error;
            } else {
              Toast("success", "Literature Review generated");
              let replace = action.payload.data.response.replace("[0m", "");
              found.literature = replace;
              found.sourceArr = Object.values(action.payload.data.sourceObj);
              found.sourceObj = action.payload.data.sourceObj;
              found.lastString = action.payload.data.lastString;
              found.libraryReferences =
                action.payload.data.sourceObj.references;
            }
          }
        }
      }
    });
    builder.addCase(getReview.rejected, (state, action) => {
      state.loading = false;
      state.progress = 0;
      state.interval = 500;
      state.error = action.error.message;
    });
  },
});

export const {
  clearQuery,
  addReview,
  addQuery,
  addProgress,
  clearLitReview,
  addProjectTitle,
  addProject,
  setPasteContent,
  clrTracker,
  clrLoading,
  handleSession,
  clearAllLitReview,
  setLitDomain,
  clearLitDomain,
  setSelectedFormat,
  clearSelectedFormat,
  setValue,
  clearValue,
  showToast, hideToast,
  abortReviewFetch,
  clearLitLoading,
  abortCancelFetch,
  clearLiterature
} = reviewSlice.actions;

export default reviewSlice.reducer;
