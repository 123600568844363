import { Stack, Typography } from "@mui/material";
import React from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Scrollbar } from "react-scrollbars-custom";
import { AutoStories, Link, Person, Source } from "@mui/icons-material";

function Abstract({ file, data }) {
  return (
    <div>
      {" "}
      <Stack
        sx={{
          background: "#FFFFFF",
          // boxShadow: "0px 3.57397px 40.2071px rgba(0, 0, 0, 0.08)",
          // padding: "10px",
          width: "800px",

          borderRadius: "8px",
          overflow: "none",
        }}
      >
        {/* <CancelOutlinedIcon onClick={() => file("tiny")} /> */}
        <Stack justifyContent="center" alignItems="center">
          <Stack direction="row">
            {data?.title && (
              <Stack justifyContent="center" alignItems="center" px={10}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 550 }}
                  textAlign={"center"}
                >
                  {data?.title}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack sx={{ padding: 4 }} spacing={2}>
          <Stack
            sx={{
              color: "#35353599",
              px: 1,
            }}
            direction="row"
          >
            <Person sx={{ mr: 1 }} fontSize="small" />

            <Stack flexWrap="wrap" direction="row">
              {(data?.authors || data?.author) && (
                <Typography sx={{ fontSize: "12px" }}>
                  {data?.author}
                </Typography>
              )}
              {data?.authors &&
                data?.authors.map((info, id) => (
                  <Typography
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    {`${info.name} , `}
                  </Typography>
                ))}
            </Stack>
          </Stack>

          <Stack sx={{ color: "#35353599" }} direction="row">
            <AutoStories sx={{ mx: 1 }} fontSize="small" />

            {(data?.journal || data?.journal?.name) && (
              <Typography sx={{ fontSize: "12px" }}>
                {data?.journal?.name || JSON.stringify(data?.journal)}
              </Typography>
            )}
          </Stack>
          <Stack spacing={2}>
            <Stack sx={{ color: "blue" }} direction="row">
              <Link
                sx={{ mx: 1, color: "#35353599 !important" }}
                fontSize="small"
              />
              {data?.url && (
                <a
                  href={data?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="blue"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {data?.url}
                </a>
              )}
            </Stack>
            <Stack sx={{ color: "#35353599" }} direction="row">
              {data?.year && (
                <>
                  <CalendarMonthIcon sx={{ mx: 1 }} fontSize="small" />
                  <Typography sx={{ fontSize: "12px" }}>
                    {data?.year}
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
          <Typography variant="h6" sx={{ mb: 2, fontSize: "16px" }}>
            Abstract
          </Typography>
          <Typography sx={{ fontSize: "14px" }} textAlign={"justify"}>
            {data?.abstract || data?.abstracts}
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
}

export default Abstract;
