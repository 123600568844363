import { Box, Grid, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import LandingHeader from "../../../layout/header";
import { useLocation } from "react-router-dom";
import Login from "../../authentication/login";
import ForgotPassword from "../../Admin/ForgotPassword";
import Signup from "../../authentication/sign up";

import HeroSection from "./HeroSection";
import TrustedBySection from "./TrustedBySection";
import PlatformSection from "./PlatformSection";
import ModalsSection from "./ModalsSection";
import EditorSection from "./EditorSection";
import AcedmicsSection from "./AcedmicsSection";
import Footer from "../../../layout/footer";
import ContactUsSection from "./ContactUsSection";
import hero_bg from "../../../assets/images/NewImages/hero_bg.svg";
const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "700px",
  padding: "10px 202px 100px 202px",
  // marginBottom: "10vh",
  backgroundColor: "#fff",
  // marginInline: "10px",
  marginTop: "10vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "38px",
    padding: "64px 10px 0 10px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "38px",
    padding: "64px 10px 0 10px",
  },
}));

const CardGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Righteous",
  fontWeight: 400,
  fontSize: "40px",
  color: "#353535",
  textAlign: "center",
  marginBottom: "64px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));
const Universities = () => {
  const location = useLocation();

  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
    contactUsModal: false,
  });
  const toggle = (name) => {
    if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${hero_bg})`,
        backgroundSize: "cover",
        position: "relative",
        padding: "0px !important",
        margin: "0px !important",
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <LandingHeader onClick={(e) => toggle(e)} />
      </Box>

      <HeroSection />
      <TrustedBySection />
      <RootStyle>
        <PlatformSection />
        <ModalsSection />
        <EditorSection />
        <AcedmicsSection />
      </RootStyle>
      <Footer />

      {state.loginModal && (
        <Login open={state.loginModal} onClose={(e) => toggle(e)} />
      )}
      {state.forgotModal && (
        <ForgotPassword
          open={state.forgotModal}
          onClose={() => toggle("forgotModal")}
        />
      )}
      {state.signupModal && (
        <Signup
          open={state.signupModal}
          onClose={() => toggle("signupModal")}
        />
      )}
    </Box>
  );
};

export default Universities;
