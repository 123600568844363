import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Doc as YDoc } from "yjs";

import { createPortal } from "react-dom";
import { Surface } from "./components/ui/Surface";
import { Toolbar } from "./components/ui/Toolbar";
import Icon from "./components/ui/Icon";
import BlockEditor from "./components/BlockEditor";
import "iframe-resizer/js/iframeResizer.contentWindow";
import ReactGA from "../../Analytics";
import "./editor.css";
import { Tooltip } from "./components/ui/Tooltip/Tooltip";
import { delay } from "lodash";
import { Stack } from "@mui/material";
const useDarkmode = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    typeof window !== "undefined"
      ? window.matchMedia("(prefers-color-scheme: dark)").matches
      : false
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = () => setIsDarkMode(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleChange);
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDarkMode]);

  const toggleDarkMode = useCallback(
    () => setIsDarkMode((isDark) => !isDark),
    []
  );
  const lightMode = useCallback(() => setIsDarkMode(false), []);
  const darkMode = useCallback(() => setIsDarkMode(true), []);

  return {
    isDarkMode,
    toggleDarkMode,
    lightMode,
    darkMode,
  };
};

export default function TipTapEditor({ params }) {
  const { isDarkMode, darkMode, lightMode } = useDarkmode();
  const [isAbstractDrawerOpen, setIsAbstractDrawerOpen] = useState(false);
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/Editor",
      title: "Editor",
    });
  }, []);
  const handleAbstractDrawer = () => {
    setIsAbstractDrawerOpen((isOpen) => !isOpen);
  };
  const handleHistoryDrawer = () => {
    setIsHistoryDrawerOpen((isOpen) => !isOpen);
  };
  // const [aiToken, setAiToken] = useState(null);

  // useEffect(() => {
  //   // fetch data
  //   const dataFetch = async () => {
  //     const data = await (
  //       await fetch("/api/ai", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       })
  //     ).json();

  //     const { token } = data;

  //     // set state when the data received
  //     setAiToken(token);
  //   };

  //   dataFetch();
  // }, []);

  const ydoc = useMemo(() => new YDoc(), []);

  const AbstractDrawer = createPortal(
    <Surface className="flex items-center gap-1 fixed top-16 right-6  p-1">
      <Stack direction="row" spacing={1}>
        <Tooltip
          title="View History"
          tippyOptions={{
            delay: 0,
          }}
        >
          <Toolbar.Button onClick={handleHistoryDrawer}>
            <Icon name="Clock" />
          </Toolbar.Button>
        </Tooltip>
        <Tooltip
          title="View Abstract"
          tippyOptions={{
            delay: 0,
          }}
        >
          <Toolbar.Button onClick={handleAbstractDrawer}>
            <Icon name="File" />
          </Toolbar.Button>
        </Tooltip>
      </Stack>
    </Surface>,
    //apply only to pros mirror class name
    document.body
  );

  return (
    <div className="flex flex-col">
      {AbstractDrawer}
      <BlockEditor
        ydoc={ydoc}
        isAbstractDrawerOpen={isAbstractDrawerOpen}
        setIsAbstractDrawerOpen={setIsAbstractDrawerOpen}
        isHistoryDrawerOpen={isHistoryDrawerOpen}
        setIsHistoryDrawerOpen={setIsHistoryDrawerOpen}
      />
    </div>
  );
}
