import * as Popover from "@radix-ui/react-popover";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { memo, useState } from "react";
import { cn, getConnectionText } from "../../lib/utils";
import { Tooltip } from "@mui/material";
import { Button } from "../ui/Button/Button";
import { Toolbar } from "../ui/Toolbar";
import { Surface } from "../ui/Surface";
import { DropdownButton } from "../ui/Dropdown/Dropdown";
import Icon from "../ui/Icon";
import { saveHistory, updateResearchProject } from "../../../../apiservice";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTime } from "../../../editorSlice";
import Repo from "../../../../repo/Repo";
import { getUser } from "../../../Admin/userSlice";
import { handleDownloadDoc } from "./Export/DownloadDoc";
import { printHandler } from "./Export/DownloadPdf";
import { handleDownloadLatex } from "./Export/DownloadLatex";
import { handleDownloadHtml } from "./Export/DownloadHtml";
import { useNavigate } from "react-router-dom";
import NewPdfPrint from "./Export/NewPdfPrint";
import { clearRefs, getRefs } from "../../../references/refsSlice";

export const EditorInfo = memo(({ characters, words, editor }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { currentTime } = useSelector((state) => state.editor);
  const { refFormats } = useSelector((state) => state.refs);
  const { user } = useSelector((state) => state.user);
  const defaultModel =
    user.subscription.planName === "pro"
      ? "Claude 3.5 Sonnet"
      : "GPT-3.5 Turbo";
  // const [modelData, setModelData] = useState(user.model || "GPT-3.5 Turbo");
  const [modelData, setModelData] = useState(user.model || defaultModel);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttonStyle, setButtonStyle] = useState({
    background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
  });

  const handleMouseEnter = () => {
    setButtonStyle({
      background: "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
      color: "#ffff",
    });
  };

  const handleMouseLeave = () => {
    setButtonStyle({
      background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
      color: "#ffff",
    });
  };

  const freelongText = (
    // <p>
    // <h6>Standard :</h6>
    // <p>1: More usage</p>
    // <p>2: Access to Claude 3 Sonnet(Standard)</p>
    // <p>3: Access to Claude 3 Haiku(Standard)</p>
    // <p>4: Medium format Literature Review(Standard)</p>
    // <p>5: Access to GPT 4 Turbo(Pro)</p>
    // <p>6: Access to GPT 4o (Omni)(Pro)</p>
    // <p>7: Access to Claude 3 Opus(Pro)</p>
    // <p>8: Long format Literature Review(Pro)</p>
    // </p>
    <div className=" flex justify-between">
      <div>
        <h6 className=" pb-1">Standard :</h6>
        <p>1: More usage</p>
        <p>2: Access to Claude 3 Sonnet</p>
        <p>3: Access to Claude 3 Haiku</p>
        <p>4: Medium format Literature Review</p>
      </div>

      <div className=" w-[1px] bg-white mx-2"></div>

      <div>
        <h6 className="pb-1">Pro :</h6>
        <p>1: Access to GPT 4 Turbo</p>
        <p>2: Access to GPT 4o (Omni)</p>
        {/* <p>3: Access to Claude 3 Opus</p> */}
        <p>3: Access to Claude 3.5 Sonnet</p>
        <p>4: Access to Gemini 1.5 pro</p>
        <p>5: Long format Literature Review</p>
      </div>
    </div>
  );
  const standardlongText = (
    <p>
      <h6 className=" pb-1">Pro :</h6>
      <p>1: More usage</p>
      <p>2: Access to GPT 4 Turbo</p>
      <p>3: Access to GPT 4o (Omni)</p>
      {/* <p>4: Access to Claude 3 Opus</p> */}
      <p>4: Access to Claude 3.5 Sonnet</p>
      <p>5: Access to Gemini 1.5 pro</p>
      <p>6: Long format Literature Review</p>
    </p>
  );

  const updateResearch = async (content, toc) => {
    dispatch(setCurrentTime(moment().format("hh:mm:ss A")));
    const info = {
      _id: `${localStorage.getItem("projectId")}`,
      main_document: editor.getJSON(),
      wordCount: characters,
    };
    let resp = await updateResearchProject(info);
    await saveHistory({
      _id: localStorage.getItem("projectId"),
    });
    return resp;
  };
  const handleChange = async (event) => {
    const selectedMode = event.target.value;
    setModelData(selectedMode);

    try {
      let { data } = await Repo.user({
        request: {
          method: "updateUser",
          data: {
            _id: localStorage.getItem("email"),
            model: selectedMode,
          },
        },
      });
      console.log("User updated:", data);
      dispatch(getUser());
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const handleDocument = async () => {
    dispatch(getRefs(localStorage.getItem("projectId")));
    dispatch(clearRefs());
    handleDownloadDoc(editor.getHTML(), refFormats, user);
  };
  return (
    <div className="flex items-center">
      <div className="flex flex-col justify-center pr-4 mr-4 text-right border-r border-neutral-200 dark:border-neutral-200">
        <div className=" text-[14px]   text-neutral-500 dark:text-neutral-500">
          <span className="font-semibold   mr-2">Project:</span>{" "}
          {localStorage.getItem("projectTitle")}
        </div>
      </div>
      <div className="flex flex-col justify-center pr-4 mr-4 text-right border-r border-neutral-200 dark:border-neutral-200">
        <div className="text-xs font-semibold text-neutral-500 dark:text-neutral-500">
          Last Saved :{" "}
          {typeof currentTime !== "string"
            ? currentTime.format("hh:mm:ss A")
            : currentTime}{" "}
        </div>
        <div className="text-xs font-semibold text-neutral-500 dark:text-neutral-500">
          {" "}
          (AutoSave Enabled - 3sc)
        </div>
      </div>
      <div className="flex flex-col justify-center pr-4 mr-4 text-right border-r border-neutral-200 dark:border-neutral-200 w-[130px]">
        <div className="text-xs font-semibold text-neutral-500 dark:text-neutral-500">
          {words} {words === 1 ? "word" : "words"}
        </div>
        <div className="text-xs font-semibold text-neutral-500 dark:text-neutral-500 ">
          {characters} {characters === 1 ? "character" : "characters"}
        </div>
      </div>
      {user?.subscription.planName !== "pro" && (
        <div className="flex flex-col justify-center pr-4 mr-4 text-right border-r border-neutral-200 dark:border-neutral-200 ">
          <Tooltip
            title={
              user.subscription.planName === "standard"
                ? standardlongText
                : user.subscription.planName === "free-plan"
                ? freelongText
                : ""
            }
          >
            {/* <Toolbar.Button
              className="text-xs text-white  bg-[#353535] active:text-white dark:bg-[#353535] dark:text-white  dark:active:text-white hover:bg-[#353535] hover:text-white dark:hover:bg-[#353535] dark:hover:text-white"
              activeClassname="text-white bg-[#353535]"
              onClick={() => navigate("/subscription")}
            > */}
            <Toolbar.Button
              className="text-xs text-white active:text-white dark:bg-[#353535] dark:text-white dark:active:text-white"
              activeClassName="text-white bg-[#353535]"
              onClick={() => navigate("/subscription")}
              style={buttonStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Icon name="Zap" className="mr-1" />
              Upgrade
            </Toolbar.Button>
          </Tooltip>
        </div>
      )}
      <div className="flex flex-col justify-center pr-4 mr-4 text-right border-r border-neutral-200 dark:border-neutral-00">
        {/* <select
          value={modelData} // Bind the value to the state
          onChange={handleChange} // Set the state on change
          // id="countries"
          className="text-xs py-[6px] bg-gray-50 border border-gray-300 text-gray-600  rounded-md focus:ring-red-800 focus:border-[#F6F6F6] block w-auto  dark:bg-gray-50 dark:border-gray-300 dark:text-gray-600  dark:focus:ring-red-800 dark:focus:border-[#F6F6F6] dark:text-xs"
        >
          <option value="GPT-3.5 Turbo">GPT-3.5 Turbo</option>
          {user?.subscription.planName === "pro" && (
            <option value="Claude 3 Opus">Claude 3 Opus</option>
          )}
          {user?.subscription.planName === "pro" && (
            <option value="GPT-4 Turbo">GPT-4 Turbo</option>
          )}
          {user?.subscription.planName === "pro" && (
            <option value="GPT-4o">GPT-4o</option>
          )}
          {user?.subscription.planName === "standard" && (
            <option value="Claude 3 Sonnet">Claude 3 Sonnet</option>
          )}
          {user?.subscription.planName === "standard" && (
            <option value="Claude 3 Haiku">Claude 3 Haiku</option>
          )}
        </select> */}
        <select
          value={modelData} // Bind the value to the state
          onChange={handleChange} // Set the state on change
          className="text-xs py-[6px] bg-gray-50 border border-gray-300 text-gray-600  rounded-md focus:ring-red-800 focus:border-[#F6F6F6] block w-auto  dark:bg-gray-50 dark:border-gray-300 dark:text-gray-600  dark:focus:ring-red-800 dark:focus:border-[#F6F6F6] dark:text-xs"
        >
          {user.subscription.planName === "pro" ? (
            <>
              <option value="Claude 3.5 Sonnet">Claude 3.5 Sonnet</option>
              <option value="Gemini 1.5 pro">Gemini 1.5 pro</option>
              <option value="GPT-4 Turbo">GPT-4 Turbo</option>
              <option value="GPT-4o">GPT-4o</option>
            </>
          ) : (
            <>
              <option value="GPT-3.5 Turbo">GPT-3.5 Turbo</option>
              {user.subscription.planName === "standard" && (
                <>
                  <option value="Claude 3 Sonnet">Claude 3 Sonnet</option>
                  <option value="Claude 3 Haiku">Claude 3 Haiku</option>
                </>
              )}
            </>
          )}
        </select>
      </div>
      <div className="flex flex-col justify-center pr-4 mr-4 text-right border-r border-neutral-200 dark:border-neutral-200 ">
        <Toolbar.Button
          className="text-xs text-neutral-500  bg-[#F6F6F6] active:text-gray-600 dark:bg-[#F6F6F6] dark:text-neutral-500  dark:active:text-gray-600"
          activeClassname="text-gray-600 "
          onClick={updateResearch}
        >
          <Icon name="Save" className="mr-1" />
          Save
        </Toolbar.Button>
      </div>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <Toolbar.Button
            className="text-xs text-neutral-500 hover:text-gray-600 bg-[#F6F6F6] active:text-gray-600 dark:bg-[#F6F6F6] dark:text-neutral-500 dark:hover:text-gray-600 dark:active:text-gray-600"
            activeClassname="text-gray-600 hover:text-gray-600 "
          >
            <Icon name="Download" className="mr-1" />
            Export
            <Icon name="ChevronDown" className="w-2 h-2 ml-1" />
          </Toolbar.Button>
        </Dropdown.Trigger>
        <Dropdown.Content className=" z-[9999] mr-2">
          <Surface className="p-2 flex flex-col min-w-32 mt-4">
            <Dropdown.Item onClick={handleDocument}>
              <DropdownButton>
                <Icon name="FileText" />
                Word
              </DropdownButton>
            </Dropdown.Item>
            <Dropdown.Item>
              <NewPdfPrint
                editor={editor.getHTML()}
                references={refFormats}
                user={user}
              />
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleDownloadLatex(editor.getHTML(), refFormats, user);
              }}
            >
              <DropdownButton>
                <Icon name="BookMarked" />
                Latex
              </DropdownButton>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleDownloadHtml(editor.getHTML(), refFormats, user);
              }}
            >
              <DropdownButton>
                <Icon name="FileCode" />
                HTML
              </DropdownButton>
            </Dropdown.Item>
          </Surface>
        </Dropdown.Content>
      </Dropdown.Root>

      {/* <Popover.Root open={menuOpen} onOpenChange={setMenuOpen}>
        <Popover.Trigger asChild>
          <Toolbar.Button onClick={handlePopupOpen}>Export </Toolbar.Button>
        </Popover.Trigger>
        <Popover.Content side="bottom" align="start" sideOffset={12}>
          <Surface className="p-2 flex flex-col min-w-32 mr-6 ">
            <Toolbar.Button
              onClick={() =>
                handleDownloadDoc(editor.getHTML(), refFormats, user)
              }
            >
              <Icon name="FileText" />
              Word
            </Toolbar.Button>
            <DropdownButton
            // onClick={actions.copyNodeToClipboard}
            >
              <Icon name="FileText" />
              PDF
            </DropdownButton>

            <DropdownButton
            // onClick={actions.duplicateNode}
            >
              <Icon name="BookMarked" />
              Latex
            </DropdownButton>

            <DropdownButton
            // onClick={actions.deleteNode}
            >
              <Icon name="FileCode" />
              HTML
            </DropdownButton>

            <Popover.Arrow />
          </Surface>
        </Popover.Content>
      </Popover.Root> */}
    </div>
  );
});

EditorInfo.displayName = "EditorInfo";
