import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, FormControl, Input } from "@mui/material";
import { DialogContent, Stack, Typography } from "@mui/material";
import Repo from "../../repo/Repo";
import { TailSpin } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "../../Analytics";
import { useLocation, useNavigate } from "react-router-dom";
import {
  proMonthlyLookupKey,
  proYearlyLookupKey,
  standardMonthlyLookupKey,
  standardYearlyLookupKey,
} from "../../repo/Repository";
import { clearUser } from "../Admin/userSlice";
import Toast from "../../components/Toast";

export default function NewPayment({ onClose, plan, billing }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("plan:", plan);
  console.log("billing:", billing);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [users, setUsers] = useState("");
  console.log("user:", user);
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Subscription",
    });
    getUser();
  }, []);

  const getUser = async () => {
    setLoading(true);
    try {
      let { data } = await Repo.user({
        request: {
          method: "getUser",
          data: {
            _id: localStorage.getItem("email"),
            token: localStorage.getItem("token") || "",
          },
        },
      });
      setLoading(false);
      if (data.response.status.statusCode == 200) {
        setUsers(data.response.data.userObj);
        localStorage.setItem(
          "customerId",
          data.response.data.userObj.customerId || ""
        );
      } else if (
        data.response.data.error == "Invalid Token" ||
        data.response.data.error == "Invalid token"
      ) {
        Toast("info", "Session has expired. Please login again");
        localStorage.clear();
        dispatch(clearUser());
        navigate("/");
      } else if (data.response.data.error == "Token is empty!") {
        Toast("info", "Session has expired. Please login again");
        localStorage.clear();
        dispatch(clearUser());
        navigate("/");
      } else {
        Toast("error", data.response.status.statusMessage);
      }
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      ReactGA.event({
        category: "User",
        action: "Click Checkout",
        label: `Plan: ${plan}, Billing: ${billing} user: ${user.email}`,
      });

      // Send conversion event to Google Ads
      window.gtag("event", "conversion", {
        send_to: "AW-818680928/PP44CKn0ybAZEOCosIYD", // Replace with your conversion ID
        value: 1.0, // Optional: Add a value for the conversion
        currency: "USD", // Optional: Specify the currency
      });

      let { data } = await Repo.subscribe({
        request: {
          method: "createCheckoutSession",
          data: {
            lookupKey:
              billing === "yearly" && plan === "standard"
                ? standardYearlyLookupKey
                : billing === "monthly" && plan === "standard"
                ? standardMonthlyLookupKey
                : billing === "yearly" && plan === "pro"
                ? proYearlyLookupKey
                : billing === "monthly" && plan === "pro"
                ? proMonthlyLookupKey
                : proMonthlyLookupKey,

            email: localStorage.getItem("email"),
            customerId: localStorage.getItem("customerId"),
          },
        },
      });
      setLoading(false);
      //open in same tab
      window.location.href = data.response.data.sessionUrl;
      onClose();
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="xs">
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            rowGap: "16px",
          }}
        >
          <Stack direction="row" alignItems="center" spacing="8px">
            <Typography color="#14224B" fontWeight={600}>
              Package :-{" "}
            </Typography>
            <Typography sx={{ color: "#14224B" }}>
              {plan === "standard"
                ? "STANDARD"
                : plan === "pro"
                ? "PRO"
                : "ENTERPRISE"}
            </Typography>
          </Stack>
          <Typography color="#14224B" fontWeight={600}>
            {billing === "yearly" && plan === "standard"
              ? "$99 / yearly"
              : billing === "monthly" && plan === "standard"
              ? "$9 / month"
              : billing === "yearly" && plan === "pro"
              ? "$359.88 / yearly"
              : billing === "monthly" && plan === "pro"
              ? "$39.99 / month"
              : ""}
          </Typography>
          {loading && (
            <TailSpin height="37" width="37" color="#5AB7BF" visible={true} />
          )}
          {!loading && (
            <Button
              fullWidth
              variant="contained"
              sx={{
                background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                textTransform: "none",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                },
              }}
              onClick={handleSubmit}
            >
              Checkout
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
