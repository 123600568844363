import React, { useState } from "react";
import { Button, Dialog } from "@mui/material";
import { DialogContent, Stack, TextField, Typography } from "@mui/material";
import Repo from "../../repo/Repo";
import TailSpinLoader from "../../components/TailSpinLoader";
import Toast from "../../components/Toast";

function ForgotPassword({ open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const handleInput = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleForgot = async () => {
    setLoading(true);
    let { data } = await Repo.email({
      request: {
        method: "sendForgetPasswordLink",
        data: {
          to: email,
        },
      },
    });
    Toast("success", "Check your email to reset password.");
    onClose();
  };
  const toggle = () => {
    if (!loading) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={toggle} fullWidth maxWidth="xs">
      <DialogContent
        sx={{
          background: "#f6f6f6",
        }}
      >
        <Stack spacing={2.4}>
          <Stack spacing={0.8}>
            <Typography
              sx={{
                fontWeight: 600,
                fontFamily: "Raleway",
                fontSize: "14px",
                color: "#14224B",
              }}
            >
              Email
            </Typography>
            <TextField
              fullWidth
              size="small"
              sx={{
                background: "#fff",
                border: "none",
                borderRadius: "4px",
                "& fieldset": { border: "none" },
              }}
              placeholder="example@email.com"
              value={email}
              onChange={handleInput}
            />
          </Stack>

          {loading ? (
            <TailSpinLoader />
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Button
                fullWidth
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "#ffffff !important",
                  color: "#14224B",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  textTransform: "none",
                  background:
                    "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                  },
                }}
                onClick={handleForgot}
              >
                Submit
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default ForgotPassword;
