import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Toolbar,
  ListItemButton,
  ListItemIcon,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import colorLogo from "../../../assets/images/NewImages/colorLogo.svg";
import colorLogo from "../../../assets/images/NewImages/sidebar-RP.svg";
import ResearchPal from "../../../assets/images/NewImages/ResearchPal.svg";
// import ResearchPal from "../../../assets/images/NewImages/sidebar-RP.svg";

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0, // Set width to 0 when closed
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  boxShadow: "none",
}));

const CustomDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ApiSiderBar = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isSmallScreen); // Initially open for larger screens
  const [activeItem, setActiveItem] = useState(null);

  const handleClick = (id) => {
    setActiveItem(id);
  };

  const [dropDownOpen, setDropDownOpen] = React.useState({
    "Literature Review": false,
    "Paper Insights": false,
    Search: false,
    "Generate Reference": false,
    "Ask ResearchPal": false,
    Cite: false,
    Tones: false,
    "Text Tuner": false,
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDropDown = (itemName) => {
    setDropDownOpen((prevState) => ({
      ...prevState,
      [itemName]: !prevState[itemName],
    }));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: "#EEFDFB",
          height: { xs: "auto", sm: "40px !important" },
          display: "flex",
          justifyContent: "center",
          paddingY: "4px",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            size="small"
            sx={{
              marginRight: { xs: 0, sm: 3 },
              ...(open && { display: "none" }),
              background: "linear-gradient(90deg, #5671F1, #17CEAD)",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: { xs: "space-evenly", sm: "space-between" },
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                fontWeight: "700",
                background: "linear-gradient(90deg, #5671F1, #17CEAD)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              ResearchPal API
            </Typography>
            {!isSmallScreen || !open ? (
              <Link to={"/"}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    // background: "linear-gradient(90deg, #5671F1, #17CEAD)",
                    background: "#727272",
                    border: "1px solid #727272",
                    color: "white",
                    height: { xs: "auto", sm: "28px" },
                    borderRadius: "6px",
                    "&:hover": {
                      // background: "linear-gradient(90deg, #3046AE, #14B599)",
                      // color: "#fff",
                      color: "#353535",
                      border: "1px solid #727272",
                    },
                    fontSize: { xs: "11px", sm: "14px" },
                    textTransform: "capitalize",
                    fontWeight: "700",
                  }}
                >
                  Go Back
                </Button>
              </Link>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>

      <CustomDrawer
        variant="permanent"
        open={open}
        sx={{
          backgroundColor: "#EEFDFB !important",
        }}
      >
        <DrawerHeader
          sx={{
            minHeight: "38px !important",
          }}
        >
          <Link to={"/"}>
            {" "}
            <img
              src={colorLogo}
              alt=""
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </Link>
          <Link to={"/"}>
            <img src={ResearchPal} alt="" />
          </Link>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{}}>
            <ListItemText
              primary="Outline"
              primaryTypographyProps={{
                sx: {
                  fontSize: "14px",
                  paddingLeft: "3px",
                  fontWeight: "700",
                  color: "#373535",
                  paddingLeft: "14%",
                },
              }}
            />
          </ListItem>
          <List component="div" disablePadding sx={{}}>
            <ListItemButton
              sx={{ pl: 2, "&:hover": { backgroundColor: "white !important" } }}
              onClick={() => {
                handleClick("accessing-api");
                const element = document.getElementById("accessing-api");
                if (element) {
                  const appBarHeight =
                    document.querySelector(".MuiAppBar-root").offsetHeight; // Get height directly from the DOM
                  const elementPosition = element.getBoundingClientRect().top;
                  const offsetPosition = elementPosition - appBarHeight;
                  window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                  });
                }
              }}
            >
              <ListItemText
                primary="Accessing the API"
                primaryTypographyProps={{
                  sx: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#373535",
                    pl: 2,
                    py: "6px",
                    borderRadius: "6px",
                    "&:hover": { background: "#f6f6f6", color: "#000" },
                    // "&:active": {
                    //   backgroundColor: "#353535", // Background color when active
                    //   color: "#fff", // Text color when active
                    // },
                    backgroundColor:
                      activeItem === "accessing-api"
                        ? "#f6f6f6"
                        : "transparent",
                    color: activeItem === "accessing-api" ? "#000" : "#373535",
                  },
                }}
              />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 2, "&:hover": { backgroundColor: "white !important" } }}
              onClick={() => {
                handleClick("features");
                const element = document.getElementById("features");
                const appBarHeight =
                  document.querySelector(".MuiAppBar-root").offsetHeight; // Get height directly from the DOM
                if (element) {
                  const elementPosition = element.getBoundingClientRect().top;
                  const offsetPosition = elementPosition - appBarHeight;
                  window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                  });
                }
              }}
            >
              <ListItemText
                primary="Features"
                primaryTypographyProps={{
                  sx: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#373535",
                    pl: 2,
                    py: "6px",
                    borderRadius: "6px",
                    "&:hover": { background: "#f6f6f6", color: "#000" },
                    // "&:active": {
                    //   backgroundColor: "#353535", // Background color when active
                    //   color: "#fff", // Text color when active
                    // },
                    backgroundColor:
                      activeItem === "features" ? "#f6f6f6" : "transparent",
                    color: activeItem === "features" ? "#000" : "#373535",
                  },
                }}
              />
            </ListItemButton>

            {[
              {
                name: "Literature Review",
                nameId: "literature-review",
                parameters: "API Parameters:",
                parametersId: "literature-api",
                request: "Request:",
                requestId: "literature-req",
              },
              {
                name: "Paper Insights",
                nameId: "paper-insight",
                parameters: "API Parameters:",
                parametersId: "paperInsight-api",
                request: "Request:",
                requestId: "paperInsight-req",
              },
              {
                name: "Search",
                nameId: "search",
                parameters: "API Parameters:",
                parametersId: "search-api",
                request: "Request:",
                requestId: "search-req",
              },
              {
                name: "Generate Reference",
                nameId: "gen-ref",
                parameters: "API Parameters:",
                parametersId: "genRef-api",
                request: "Request:",
                requestId: "genRef-req",
              },
              {
                name: "Ask ResearchPal",
                nameId: "ask-pal",
                parameters: "API Parameters:",
                parametersId: "askPal-api",
                request: "Request:",
                requestId: "askPal-req",
              },
              {
                name: "Cite",
                nameId: "cite",
                parameters: "API Parameters:",
                parametersId: "cite-api",
                request: "Request:",
                requestId: "cite-req",
              },
              {
                name: "Tones",
                nameId: "tones",
                parameters: "API Parameters:",
                parametersId: "tones-api",
                request: "Request:",
                requestId: "tones-req",
              },
              {
                name: "Text Tuner",
                nameId: "text-tuner",
                parameters: "API Parameters:",
                parametersId: "textTuner-api",
                request: "Request:",
                requestId: "textTuner-req",
              },
            ].map((item, index) => (
              <List key={item.name} disablePadding sx={{ px: 1, py: 1.5 }}>
                <ListItem
                  disablePadding
                  sx={{
                    pr: 2,
                    pt: 1,
                    color: "#373535",
                    // pl: 2,
                    py: "4px",
                    // backgroundColor: "#f9f9f9",
                    borderRadius: "6px",
                    "&:hover": { background: "#f6f6f6" },
                    backgroundColor:
                      activeItem === item.nameId ? "#f6f6f6" : "transparent",
                    color: activeItem === item.nameId ? "#000" : "#373535",

                    cursor: "pointer",
                  }}
                  onClick={() => toggleDropDown(item.name)}
                >
                  <ListItemText
                    primary={`${index + 1}. ${item.name}`}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#373535",
                        pl: 2,
                      },
                    }}
                  />
                  {dropDownOpen[item.name] ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </ListItem>
                {dropDownOpen[item.name] && (
                  <List disablePadding sx={{}}>
                    <ListItem
                      disablePadding
                      sx={{
                        pl: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const element = document.getElementById(item.nameId);
                        handleClick(item.nameId);
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                      }}
                    >
                      <ListItemText
                        primary="Introduction"
                        primaryTypographyProps={{
                          sx: {
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#373535",
                            pl: 5,
                            pt: 1,
                          },
                        }}
                      />
                    </ListItem>

                    <ListItem
                      disablePadding
                      sx={{
                        pl: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const element = document.getElementById(
                          item.parametersId
                        );
                        handleClick(item.nameId);
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                      }}
                    >
                      <ListItemText
                        primary="API Parameters"
                        primaryTypographyProps={{
                          sx: {
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#373535",
                            pl: 5,
                            pt: 1,
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      disablePadding
                      sx={{
                        pl: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const element = document.getElementById(item.requestId);
                        handleClick(item.nameId);
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                      }}
                    >
                      <ListItemText
                        primary="Request"
                        primaryTypographyProps={{
                          sx: {
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#373535",
                            pl: 5,
                            pt: 1,
                          },
                        }}
                      />
                    </ListItem>
                  </List>
                )}
              </List>
            ))}
          </List>
        </List>
        <Divider />
      </CustomDrawer>
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default ApiSiderBar;
