import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent } from "@mui/material";
import { DialogTitle, IconButton } from "@mui/material";
import { MobileStepper, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import BoardingResearchPal from "../../assets/images/BoardingResearchPal.png";
import Upload_Files_Refrences_and_Chat from "../../assets/videos/Upload_Files_Refrences_and_Chat.gif";
import createProject from "../../assets/images/features/createProject.gif";
import Document from "../../assets/images/features/Document.gif";
import aiCommands from "../../assets/images/features/aiCommands.gif";
import LiteratureReview from "../../assets/images/features/LiteratureReview.gif";
import References from "../../assets/images/features/References.gif";
import searchPapers from "../../assets/images/features/searchPapers.gif";
import paperInsights from "../../assets/images/features/paperInsights.gif";
import Library from "../../assets/images/features/Library.gif";
import PDF_Chat from "../../assets/videos/PDF_Chat.gif";
import ResearchpalDocumentLogo from "../../assets/images/NewImages/ResearchpalDocumentLogo.svg";
const ProductFeatures = ({ openDialogue, onClose }) => {
  const steps = [
    {
      label: "Create Project",
      imgPath: createProject,
      description:
        "In order to create a project, you need to click on the plus icon on top left corner of the screen. You can create multiple projects and organise your work.",
    },
    {
      label: "Document",
      imgPath: Document,
      description:
        "A Document is a feature that enhances the user's ability to create and edit content with various formatting options. Unlike plain text editors, rich text editors provide a range of features similar to those found in word processing software.",
    },
    {
      label: "Document",
      subheading: "AI Commands and In-Text Citations",
      imgPath: aiCommands,
      description:
        "Use 'AI Tools' to get quick answers to any question you have and add the information into your document. If you need to site a chunk of text in your document, write the topic name or highlight a chunk of text then use the 'cite' feature to get citations from multiple sources. Both these features can be accessed by highlighting your text and then under 'AI Tools' tab in the popup menu select either 'Ask ResearchPal' or 'Cite' feature.",
    },
    {
      label: "Literature Review",
      imgPath: LiteratureReview,
      description:
        "Use literature review section to generate automated literature reviews with correct citations based on your research question. Once you click the 'paste in editor' button, literature review will be added as a part of your document, abstracts against the citations will be saved in the library section for your reference later and all cited references will be added to the dedicated Reference section. You can also click the 'View references' button to see the research papers that have been cited in the lit review.",
    },
    {
      label: "References",
      imgPath: References,
      description:
        "This is a dedicated reference section which allows you to view the references that are generated by the In-Text Citations, Literature Review, Uploaded PDFs and Search Papers. You can view the references by clicking on the References button. You can also convert references in different formats like MLA, Chicago, Harvard and APA.",
    },
    {
      label: "Search Papers",
      imgPath: searchPapers,
      description:
        "Search relevant research papers across multiple sources against your research question. You can view the abstracts of these papers by clicking on the cards and can choose which relevant abstracts to save in the library by clicking the 'Save' button. References against the saved abstracts will be added in the dedicated reference section as well.",
    },
    {
      label: "Paper Insight",
      imgPath: paperInsights,
      description:
        "In the Library section, choose multiple files from 'My Uploads' section and click 'Add To Paper Insights' button on top right. From the Add Column button at the top, you can select the insights you want to see from all the selected papers including Conclusion, Methods Used, Summarise Abstracts, Limitations and Contributions etc.",
    },
    {
      label: "Library",
      imgPath: Library,
      description:
        "Library section lets you keep all the files organised. 'Saved abstracts' radio button will display all the abstracts saved from either the 'Literature review' or the 'Search papers' section. 'My Uploads' radio button is where you get to upload your research papers (pdfs). You can also import pdf files from Zotero and Mendeley. When you upload a file, ResearchPal tries to find relevant citation against it for your convenience and saves it in the 'Reference' section.",
    },
    {
      label: "PDF chat",
      imgPath: PDF_Chat,
      description:
        "You can click the Title of the uploaded papers to chat with them and extract relevant insights through questions. You have an option to choose from our standard questions as well.",
    },
  ];

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      onClose={() => onClose("openDialogue")}
      open={openDialogue}
      fullWidth
      maxWidth="lg"
      sx={{
        padding: "0px !important",
        "& .MuiDialog-paper": {
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        fontSize={12}
        fontWeight={600}
        fontFamily={"Raleway"}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start", // Align to the left
          textAlign: "center",
          scrollBehavior: "smooth",
          scrollMarginTop: "100px",
        }}
      >
        <div
          style={{
            marginRight: "10px",
            display: "flex",
            width: "auto",
            fontSize: "16px",
            justifyContent: "center",
            alignItems: "center",
            color: "#14224B",
          }}
        >
          <img
            src={ResearchpalDocumentLogo}
            alt="logo"
            height="30px"
            width="30px"
            style={{ marginRight: "10px" }}
          />
          Welcome To ResearchPal
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "65%",
            fontSize: "16px",
            color: "#14224B",
          }}
        >
          Take a tour of the platform
        </div>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onClose("openDialogue")}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "100%",
          }}
        >
          <Stack direction={"row"}>
            <Stack
              square
              elevation={0}
              width={"100%"}
              spacing={2}
              px={2}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",

                bgcolor: "background.default",
              }}
            >
              <Typography
                fontSize={18}
                fontWeight={600}
                fontFamily={"Raleway"}
                color={"#14224B"}
              >
                {steps[activeStep].label}
              </Typography>
              {steps[activeStep].subheading && (
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  fontFamily={"Raleway"}
                  color={"#14224B"}
                >
                  {steps[activeStep].subheading}
                </Typography>
              )}
              <Typography
                fontSize={16}
                fontFamily={"Raleway"}
                sx={{
                  textAlign: "justify",
                }}
              >
                {steps[activeStep].description}
              </Typography>
            </Stack>

            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                height={"100%"}
                width={"100%"}
                style={{
                  borderRadius: "16px",
                  margin: "4px",
                  border: "1px solid #EFEFEF",
                  shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
                src={steps[activeStep].imgPath}
                alt="kuchbhi"
              />
            </Box>
          </Stack>
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              activeStep === maxSteps - 1 ? (
                <Button
                  size="small"
                  onClick={() => onClose("openDialogue")}
                  variant="contained"
                  sx={{
                    background:
                      "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                    "&:hover": {
                      background:
                        "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                    },
                  }}
                >
                  Finish
                </Button>
              ) : (
                <Stack
                  width={"auto"}
                  justifyContent={"flex-end"}
                  direction={"row"}
                  spacing={2}
                >
                  <Button
                    size="small"
                    onClick={() => onClose("openDialogue")}
                    variant="contained"
                    sx={{
                      background:
                        "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                      "&:hover": {
                        background:
                          "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                      },
                    }}
                  >
                    Skip
                  </Button>
                  <Button
                    size="small"
                    onClick={handleNext}
                    sx={{
                      color: "#353535",
                    }}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                </Stack>
              )
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                sx={{
                  color: "#353535",
                }}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProductFeatures;
