import { Box, Button, styled } from "@mui/material";

export const BtnLogin = styled(Button)({
  textTransform: "none",
  height: "27px",
  color: "#FFF",
  fontFamily: "Raleway",
  padding: "0 20px",
  background:
    "linear-gradient(90deg, rgba(86, 113, 241, 1) 0%, rgba(23, 206, 173, 1) 100%)",
  "&:hover": {
    boxShadow: "none",
    background:
      "linear-gradient(90deg, rgba(48, 70, 174, 1) 0%, rgba(20, 181, 153, 1) 100%)",
  },
});
export const BtnPrice = styled(Button)({
  textTransform: "none",
  height: "27px",
  // color: "#353535",
  color:"#535D7B",
  padding: "0 20px",
  fontWeight: 600,
  fontFamily: "Raleway",
  background: "transparent",
  "&:hover": {
    background: "#E5E5E5",
    color:"#14224B"
  },
  "&.clicked": {
    background: "#D6D6D6",
  },
});
export const BtnFAQs = styled(Button)({
  textTransform: "none",
  height: "27px",
  padding: "0 20px",
  // color: "#353535",
  color:"#535D7B",
  fontWeight: 600,
  fontFamily: "Raleway",
  background: "transparent",
  "&:hover": {
    background: "#E5E5E5",
    color:"#14224B"
  },
  "&.clicked": {
    background: "#D6D6D6",
  },
});
export const BtnUniversity = styled(Button)({
  textTransform: "none",
  height: "27px",
  padding: "0 20px",
  // color: "#353535",
  color:"#535D7B",
  fontWeight: 600,
  fontFamily: "Raleway",
  background: "transparent",
  "&:hover": {
    background: "#E5E5E5",
    color:"#14224B"
  },
  "&.clicked": {
    background: "#D6D6D6",
  },
});
export const BtnTermsAndCondition = styled(Button)({
  textTransform: "none",
  height: "27px",
  padding: "0 20px",
  // color: "#353535",
  color:"#535D7B",
  fontWeight: 600,
  fontFamily: "Raleway",
  background: "transparent",
  "&:hover": {
    background: "#E5E5E5",
    color:"#14224B"
  },
  "&.clicked": {
    background: "#D6D6D6",
  },
});
export const BtnAPI = styled(Button)({
  textTransform: "none",
  height: "27px",
  padding: "0 20px",
  // color: "#353535",
  color:"#535D7B",
  fontWeight: 600,
  fontFamily: "Raleway",
  background: "transparent",
  "&:hover": {
    background: "#E5E5E5",
    color:"#14224B"
  },
  "&.clicked": {
    background: "#D6D6D6",
  },
});
export const BtnSetting = styled(Button)({
  textTransform: "none",
  height: "27px",
  padding: "6px 12px",
  // color: "#353535",
  color:"#535D7B",
  fontWeight: 600,
  fontsize: "13px",
  fontFamily: "Raleway",
  background: "#F6F6F6 !important",
  "&:hover": {
    background: "#E5E5E5 !important",
  },
  "&.clicked": {
    background: "#D6D6D6",
  },
});

export const MenuBox = styled(Box)(({ theme }) => ({
  background: "#f3f3f3",
  borderRadius: "0 0 6px 6px",
  minHeight: "110px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  display: "none",
  gap: "8px",
  padding: "10px",
  marginTop: "5px",
  zIndex: 20,
  // position: "absolute",
  [theme.breakpoints.down("md")]: {
    display: "flex",
  },
}));

export const RootStyle = styled(Box)({
  background: "#f3f3f3",
  borderRadius: "6px",
  height: "50px",
});
