import { EditorContent, PureEditorContent } from "@tiptap/react";
import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getResearchReferences,
  saveHistory,
  saveResearchReferences,
  updateResearchProject,
} from "../../../../apiservice";
import Toast from "../../../../components/Toast";
import { baseDomain } from "../../../../repo/Repository";

import { setPasteContent } from "../../../literature review/reviewSlice";
import { clearRefs, getRefs } from "../../../references/refsSlice";
import EditorContext from "../../context/EditorContext";
import ImageBlockMenu from "../../extensions/ImageBlock/components/ImageBlockMenu";
import ColumnsMenu from "../../extensions/MultiColumn/menus/ColumnsMenu";
import TableColumnMenu from "../../extensions/Table/menus/TableColumn";
import TableRowMenu from "../../extensions/Table/menus/TableRow";
import { useAIState } from "../../hooks/useAiState";
import { useBlockEditor } from "../../hooks/useBlockEditor";
import { Sidebar } from "../SideBar/Sidebar";
import { ContentItemMenu } from "../menus/ContentItemMenu/ContentItemMenu";
import { TextMenu } from "../menus/TextMenu/TextMenu";
import LinkMenu from "../menus/linkMenu/LinkMenu";
import Loader from "../ui/Loader/Loader";
import { EditorHeader } from "./EditorHeader";
import moment from "moment/moment";
import { setCurrentTime, textSelection } from "../../../editorSlice";
import SimpleAccordion from "../../../abstract/AbstractFiles";
import { Tooltip } from "../ui/Tooltip/Tooltip";
import { DOMParser as ProseMirrorDOMParser } from "prosemirror-model";
import { schema } from "prosemirror-schema-basic";
import History from "./HistorySection";
import HistoryDialogue from "./HistorySection/HistoryDialogue";

export const BlockEditor = ({
  provider,
  isAbstractDrawerOpen,
  setIsAbstractDrawerOpen,
  isHistoryDrawerOpen,
  setIsHistoryDrawerOpen,
}) => {
  const aiState = useAIState();
  const dispatch = useDispatch();
  const menuContainerRef = useRef(null);
  const editorRef = useRef(PureEditorContent || null);
  const projectTitle = localStorage.getItem("projectTitle");
  const projectId = localStorage.getItem("projectId");
  const { pasteContent } = useSelector((state) => state.review);
  const reviewProjects = useSelector((state) => state.review.projects);
  let currPro = reviewProjects.find((i) => i.title == projectTitle);
  let sourceObj = currPro?.sourceObj || "";
  const [editorData, setEditorData] = useState([]);
  const [previousContent, setPreviousContent] = useState(null);
  const [previousHistoryContent, setPreviousHistoryContent] = useState(null);
  const [isContentChanged, setIsContentChanged] = useState(false);
  const [isHistoryContentChanged, setIsHistoryContentChanged] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [editorLoader, setEditorLoader] = useState(false);
  const [mainData, setMainData] = useState({});
  const [pasteContentCheck, setPasteContentCheck] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [HistoryData, setHistoryData] = useState([]);
  const [editorHistoryData, setEditorHistoryData] = useState([]);

  const [state, setState] = useState({
    right: false,
    newData: {},
  });
  const Tip =
    "Abstracts from “literature review” or “Get Abstracts” from web feature will be saved here and can be referenced later.";
  const { editor, characterCount, leftSidebar } = useBlockEditor({
    provider,
  });

  useEffect(() => {
    getProjects();
  }, []);
  useEffect(() => {
    if (pasteContent) {
      handlePaste();
    }
  }, [pasteContent]);

  useEffect(() => {
    const handleContentChange = () => {
      const currentContent = editorData || editorHistoryData;
      if (currentContent !== previousContent) {
        setIsContentChanged(true);
      }
      setPreviousContent(currentContent);
    };
    editor?.on("update", handleContentChange);

    return () => editor?.off("update", handleContentChange);
  }, [editor]);

  useEffect(() => {
    const handleContentChange = () => {
      const currentContent = editorHistoryData;
      if (currentContent !== previousHistoryContent) {
        setIsHistoryContentChanged(true);
      }
      setPreviousHistoryContent(currentContent);
    };
    editor?.on("update", handleContentChange);

    return () => editor?.off("update", handleContentChange);
  }, [editor]);

  useEffect(() => {
    console.log("editorData:", editorData);
    if (editorData) {
      editor?.commands?.insertContent(editorData);
    }
  }, [editorData]);
  useEffect(() => {
    console.log("editorHistoryData:", editorHistoryData);

    if (editorHistoryData) {
      //   editor?.commands.insertContent(editorData);
      // } else {
      editor?.commands?.setContent(editorHistoryData);
    }
  }, [editorHistoryData]);

  // useEffect(() => {
  //   const intervalId = setTimeout(async () => {
  //     dispatch(setCurrentTime(moment().format("hh:mm:ss A")));
  //     const historyIntervalId = setTimeout(async () => {
  //     //   if (isContentChanged) {
  //     //     await saveHistory({
  //     //       _id: localStorage.getItem("projectId"),
  //     //     });
  //     //     setIsContentChanged(false);
  //     //   }
  //     // }, 12000);

  //     if (isContentChanged) {
  //       await updateResearch(editor.getJSON());
  //       await saveHistory({
  //         _id: localStorage.getItem("projectId"),
  //       });
  //       setIsContentChanged(false);
  //     }
  //   }, 3000);

  //   return () => {
  //     clearTimeout(intervalId);
  //   };
  // }, [isContentChanged]);
  useEffect(() => {
    const intervalId = setTimeout(async () => {
      dispatch(setCurrentTime(moment().format("hh:mm:ss A")));
      if (isContentChanged) {
        await updateResearch(editor.getJSON());

        setIsContentChanged(false);
      }
      // }, 3000);
    }, 2000);

    return () => {
      clearTimeout(intervalId);
    };
  }, [isContentChanged]);
  useEffect(() => {
    const historyIntervalId = setTimeout(async () => {
      if (isHistoryContentChanged) {
        await saveHistory({
          _id: localStorage.getItem("projectId"),
        });
        setIsHistoryContentChanged(false);
      }
    }, 8000);

    return () => {
      clearTimeout(historyIntervalId);
    };
  }, [isContentChanged]);

  const getProjects = async () => {
    setEditorLoader(true);
    // dispatch(getRefs(projectId));
    let res = await axios.post(`${baseDomain}/research-projects`, {
      request: {
        method: "getDocumentData",
        data: {
          email: localStorage.getItem("email") || "",
          title: localStorage.getItem("projectTitle") || "",
        },
      },
    });

    let docRes = res.data.response;
    let found = {};
    if (docRes) {
      found = docRes.data.researchProjects;
      setState({ ...state, newData: found });

      setMainData(found);

      setEditorData((prev) => [...prev, found?.main_document]);

      const references = found?.references || {};
      const abstracts = found?.abstracts || {};

      let mappedAbstracts = {};
      // for (const [refKey, refValue] of Object.entries(references)) {
      //   if (abstracts[refValue]) {
      //     mappedAbstracts[refKey] = abstracts[refValue];
      //   }
      // }
      for (const [refKey, refValue] of Object.entries(references)) {
        if (abstracts[refValue]) {
          mappedAbstracts[refValue] = abstracts[refValue];
        }
      }

      localStorage.setItem("editorData", JSON.stringify(found?.main_document));
      localStorage.setItem("references", JSON.stringify(found?.references));
      localStorage.setItem("abstracts", JSON.stringify(found?.abstracts));
      localStorage.setItem("mappedAbstracts", JSON.stringify(mappedAbstracts));
      setEditorLoader(false);
    }
    return found;
  };

  const updateResearch = async (content) => {
    const info = {
      _id: `${localStorage.getItem("projectId")}`,
      main_document: content,
      wordCount: characterCount.words(),
    };
    let resp = await updateResearchProject(info);
    return resp;
  };

  const providerValue = useMemo(() => {
    return {
      isAiLoading: aiState.isAiLoading,
      aiError: aiState.aiError,
      setIsAiLoading: aiState.setIsAiLoading,
      setAiError: aiState.setAiError,
    };
  }, [aiState]);

  const submitRefs = async () => {
    const valuesArray = Object.values(sourceObj);
    const str = valuesArray.join("|");
    let info = {
      papersReferences: str,
      email: localStorage.getItem("email"),
      title: projectTitle,
    };
    // if (user.subscription.planName === "pro") {
    //   info.extractProLiteraturePapers = true;
    // }
    let res = await axios.post(`${baseDomain}/script`, info);

    if (res.data.data.response == "Added") {
      dispatch(clearRefs());
    }
  };

  const handlePaste = async (e) => {
    setEditorLoader(true);
    let req = {
      researchProjectId: projectId,
      references: sourceObj,
    };
    submitRefs();
    await saveResearchReferences(req);
    console.log("projectId:", projectId);
    dispatch(clearRefs());
    let ab = await getProjects();
    // let res = await getResearchReferences({ researchProjectId: projectId });
    let main = Array.isArray(ab?.main_document);
    console.log("main:", main);
    // localStorage.setItem("references", JSON.stringify(res?.references));
    if (ab) {
      let arr = [];

      let paragraphs = pasteContent
        .split("\n")
        .filter((p) => p.trim().length > 0);
      paragraphs = paragraphs.filter((p) => p.trim().length > 0);

      paragraphs.forEach((paragraph) => {
        const parser = new DOMParser();
        const parsedHTML = parser.parseFromString(`${paragraph}`, "text/html");
        const doc = ProseMirrorDOMParser.fromSchema(schema).parse(
          parsedHTML.body
        );
        const jsonContent = doc.toJSON();
        arr.push(jsonContent);
      });

      const newElement = main ? [...ab?.main_document, ...arr] : [...arr];

      // setPasteContentCheck(true);
      setEditorData(newElement);

      setIsContentChanged(true);
      dispatch(setPasteContent(""));
    }
    dispatch(clearRefs());
    Toast("success", "Added");

    setEditorLoader(false);
  };

  // const BlueUnderlineText = (pasteContent, references, abstract) => {
  //   console.log("abstract1:", abstract);
  //   let replaced = pasteContent.replace(/[\n\r]/g, " ");
  //   let nodes = [];

  //   if (pasteContent) {
  //     const all = [
  //       /.*?\b(?:\w+\s*,\s*)+\w+\s+and\s+\w+\s+\(\d{4}\)/g,
  //       /.*?\b\w+\s+\(\d{4}\)/g,
  //       /.*?(\([^)]+\s*,\s*\d{4}\)|\w+\s+and\s+\w+\s+\(\d{4}\)|\w+\s*et\s+al\.\s+\(\d{4}\))/g,
  //     ];

  //     const regexArr = [
  //       /\b(?:\w+\s*,\s*)+\w+\s+and\s+\w+\s+\(\d{4}\)/g,
  //       /\b\w+\s+\(\d{4}\)/g,
  //       /(\([^)]+\s*,\s*\d{4}\)|\w+\s+and\s+\w+\s+\(\d{4}\)|\w+\s*et\s+al\.\s+\(\d{4}\))/g,
  //     ];

  //     let array = all.map((item) => {
  //       const section = replaced.match(item);
  //       return section;
  //     });

  //     let sections = [];
  //     if (Array.isArray(array)) {
  //       let newArr = array.filter((item) => {
  //         if (item !== null) return [...item];
  //       });
  //       let combine = [].concat(...newArr);
  //       sections = combine;
  //       // Process citations and append remaining text
  //       let remainingText = replaced;
  //       let lastCitationIndex = remainingText.lastIndexOf(
  //         sections[sections.length - 1]
  //       );
  //       console.log("lastCitationIndex:", lastCitationIndex);

  //       sections &&
  //         sections.forEach((section, index) => {
  //           if (index === sections.length - 1) {
  //             // Last citation, append remaining text
  //             let split = [];
  //             regexArr.forEach((regex) => {
  //               split = section.split(regex);
  //             });
  //             let obj = makeLink(split, references, abstract);
  //             console.log("links:", obj);
  //             obj.push({
  //               // type: "paragraph",
  //               // content: [
  //               //   {
  //               type: "text",
  //               text: remainingText.substring(
  //                 lastCitationIndex + section.length
  //               ),
  //               //   },
  //               // ],
  //             });
  //             nodes.push(...obj);
  //             //add the remaining text after the last citation
  //             // nodes.push({
  //             //   type: "paragraph",
  //             //   content: [
  //             //     {
  //             //       type: "text",
  //             //       text: remainingText.substring(
  //             //         lastCitationIndex + section.length
  //             //       ),
  //             //     },
  //             //   ],
  //             // });
  //           } else {
  //             // Process citation
  //             let split = [];
  //             regexArr.forEach((regex) => {
  //               split = section.split(regex);
  //             });
  //             let obj = makeLink(split, references, abstract);

  //             nodes.push(...obj);
  //           }
  //         });
  //     }
  //   }
  //   return nodes;
  // };

  // const makeLink = (arr, refs, abstract) => {
  //   console.log("abstract:", abstract);
  //   console.log("refs:", refs);
  //   const nodes = [];

  //   if (arr.length !== 1) {
  //     let value = refs[arr[1]];
  //     let item = abstract[value];

  //     if (item) {
  //       nodes.push({ type: "text", text: arr[0] });
  //       nodes.push({
  //         type: "text",
  //         marks: [
  //           {
  //             type: "link",
  //             attrs: {
  //               href: item.url,
  //               target: "_blank",
  //               rel: "noreferrer",
  //             },
  //           },
  //         ],
  //         text: arr[1],
  //       });
  //     } else {
  //       nodes.push({ type: "text", text: arr[0] });
  //       nodes.push({ type: "text", text: arr[1] });
  //     }
  //   } else {
  //     let value = refs[arr[0]];
  //     let item = abstract[value];

  //     if (item) {
  //       nodes.push({
  //         type: "text",
  //         marks: [
  //           {
  //             type: "link",
  //             attrs: {
  //               href: item.url,
  //               target: "_blank",
  //               rel: "noreferrer",
  //             },
  //           },
  //         ],
  //         text: arr[0],
  //       });
  //     } else {
  //       nodes.push({ type: "text", text: arr[0] });
  //     }
  //   }

  //   return nodes;
  // };

  const toggleHistoryDrawer = (anchor, open) => (event) => {
    setIsHistoryDrawerOpen(open);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    setIsAbstractDrawerOpen(open);
  };

  const historyClick = (item) => {
    if (item) {
      setOpenHistoryDialog(true);
      setHistoryData(item);
    }
  };
  const restoreHistory = (item) => {
    if (HistoryData) {
      console.log("HistoryData:", HistoryData);
      const newData = [HistoryData?.document];
      console.log("newData:", newData);
      setEditorHistoryData(newData);
      setIsContentChanged(true);
      setOpenHistoryDialog(false);
      setIsHistoryDrawerOpen(false);
      Toast("success", "Version Restored Successfully");
    }
  };
  const editorLoaderPortal = createPortal(
    <Loader label="Loading document. Please wait..." />,
    document.body
  );
  if (!editor) {
    return null;
  }

  return (
    <EditorContext.Provider value={providerValue}>
      <div className="flex max-h-[100vh]" ref={menuContainerRef}>
        <Sidebar
          isOpen={leftSidebar.isOpen}
          onClose={leftSidebar.close}
          editor={editor}
        />
        <div className="relative flex flex-col flex-1 h-screen ">
          <EditorHeader
            characters={characterCount.characters()}
            words={characterCount.words()}
            isSidebarOpen={leftSidebar.isOpen}
            toggleSidebar={leftSidebar.toggle}
            editor={editor}
          />
          <EditorContent
            editor={editor}
            id="boundary"
            ref={editorRef}
            className="flex-1 overflow-y-auto h-screen "
            style={{
              maxHeight: "100vh",
            }}
          />

          <ContentItemMenu editor={editor} />
          <LinkMenu
            editor={editor}
            appendTo={menuContainerRef}
            title="Abstract"
            tip={Tip}
            projectTitle={projectTitle}
            anchor={"right"}
            open={isAbstractDrawerOpen}
            toggleDrawer={(anchor, open) => toggleDrawer(anchor, open)}
            data={state.newData}
            file={
              state.newData?.abstracts &&
              Object.values(state.newData?.abstracts)
            }
          />
          <TextMenu editor={editor} />
          <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
          <TableRowMenu editor={editor} appendTo={menuContainerRef} />
          <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
          <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
        </div>
        {state.newData?.abstracts && (
          <div>
            <SimpleAccordion
              title="Abstract"
              tip={Tip}
              projectTitle={projectTitle}
              anchor={"right"}
              open={isAbstractDrawerOpen}
              toggleDrawer={(anchor, open) => toggleDrawer(anchor, open)}
              data={state.newData}
              file={
                state.newData?.abstracts &&
                Object.values(state.newData?.abstracts)
              }
            />
          </div>
        )}
        {
          <History
            anchor={"right"}
            open={isHistoryDrawerOpen}
            toggleDrawer={(anchor, open) => toggleHistoryDrawer(anchor, open)}
            setOpenHistoryDialog={setOpenHistoryDialog}
            openHistoryDialog={openHistoryDialog}
            historyClick={historyClick}
          />
        }
        {openHistoryDialog && (
          <HistoryDialogue
            open={openHistoryDialog}
            onClose={() => setOpenHistoryDialog(false)}
            restore={restoreHistory}
          />
        )}
      </div>
      {editorLoader && editorLoaderPortal}
    </EditorContext.Provider>
  );
};

export default BlockEditor;
