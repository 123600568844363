import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ProfileSection from "./ProfileSection";
import { Stack } from "@mui/material";
import User from "../../assets/svg/profileMan.svg";
import HistorySection from "./HistorySection";
import AdminHeader from "../../layout/admin header";
import AdminFooter from "../../layout/admin footer";
import CardInfo from "./CardInfo";
import Repo from "../../repo/Repo";
import TailSpinLoader from "../../components/TailSpinLoader";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Toast";
import { clearUser } from "../Admin/userSlice";
import { useDispatch } from "react-redux";
import hero_bg from "../../assets/images/NewImages/hero_bg.svg";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    setLoading(true);
    try {
      let { data } = await Repo.user({
        request: {
          method: "getUser",
          data: {
            _id: localStorage.getItem("email"),
            token: localStorage.getItem("token") || "",
          },
        },
      });
      setLoading(false);
      if (data.response.status.statusCode == 200) {
        setUser(data.response.data.userObj);
        localStorage.setItem(
          "customerId",
          data.response.data.userObj.customerId || ""
        );
      } else if (
        data.response.data.error == "Invalid Token" ||
        data.response.data.error == "Invalid token"
      ) {
        Toast("info", "Session has expired. Please login again");
        localStorage.clear();
        dispatch(clearUser());
        navigate("/");
      } else if (data.response.data.error == "Token is empty!") {
        Toast("info", "Session has expired. Please login again");
        localStorage.clear();
        dispatch(clearUser());
        navigate("/");
      } else {
        Toast("error", data.response.status.statusMessage);
      }
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${hero_bg})`,
        backgroundSize: "cover",
        height: "full-screen",
      }}
    >
      <Box
        sx={{
          zIndex: 1,

          mx: 3.2,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <AdminHeader />
      </Box>

      {!loading ? (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="left"
            mx={3.2}
            p={2}
          >
            <img src={User} style={{ height: "24px" }} alt="" />
            <Typography
              sx={{
                color: "#14224B",
                fontFamily: "Raleway",
                fontSize: "18px",
                ml: 2,
                fontWeight: 600,
              }}
            >
              Profile
            </Typography>
          </Stack>
          <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              textColor="#14224B"
              sx={{
                background: "#3535350F",
                width: "fit-content",
                borderRadius: "6px",
              }}
              TabIndicatorProps={{
                sx: {
                  background: "#14224B",
                  fontWeight: 600,
                },
              }}
            >
              <Tab
                label="Basic Info"
                {...a11yProps(1)}
                sx={{ textTransform: "none", color: "#14224B" }}
              />
              {/* <Tab
            label="Your Card"
            {...a11yProps(1)}
            sx={{ textTransform: "none" }}
          /> */}
              <Tab
                label="Payment History"
                {...a11yProps(1)}
                sx={{ textTransform: "none", color: "#14224B" }}
              />
            </Tabs>
          </Box>

          {value == 0 && <ProfileSection user={user} />}
          {/* {value == 1 && <CardInfo />} */}
          {value == 1 && <HistorySection user={user} />}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <TailSpinLoader />
        </Box>
      )}

      {/* <AdminFooter /> */}
    </Box>
  );
}
export default BasicTabs;
