import React from "react";
import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Grid, IconButton, Stack } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import AskFiles from "../literature review/AskFiles";

const UploadPdfDialogue = ({ handleClose, open, pdfFile }) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      fullScreen={true}
      sx={{
        padding: "0px !important",
      }}
    >
      <DialogTitle
        fontSize={12}
        fontWeight={600}
        fontFamily={"Raleway"}
        id="customized-dialog-title"
      >
        PDF FILE
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers sx={{ padding: "5px 0px 0px 16px" }}>
        <Grid
          container
          spacing={2}
          sx={{
            height: "100%",
            paddingTop: "1px !important",
          }}
        >
          <Grid
            item
            xs={8}
            sx={{
              paddingTop: "1px !important",
            }}
          >
            {pdfFile ? (
              <iframe src={pdfFile} title="iframe" width="100%" height="100%" />
            ) : (
              <Stack justifyContent="center" alignItems="center">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#353535"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </Stack>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              paddingTop: "0px !important",
            }}
          >
            <AskFiles />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UploadPdfDialogue;
