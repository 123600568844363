import React, { useRef, useState } from "react";
import { Backdrop, Box, Button, Typography } from "@mui/material";
import { CircularProgress, Dialog, DialogContent, Stack } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Btn } from "./style";
import Toast from "../../components/Toast";
import { saveEmbed, saveResearchReferences } from "../../apiservice";
import { useDispatch, useSelector } from "react-redux";
import { clearRefs } from "../references/refsSlice";
import { baseDomain } from "../../repo/Repository";
import { handlePaperLoading } from "../loader/LoaderSlice";

function UploadFile({ onSuccess }) {
  const dispatch = useDispatch();
  const [ref, setRef] = useState("");
  const fileInputRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [refLoading, setRefLoading] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(false);
  const { paperLoading } = useSelector((state) => state.loader);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // const handleUpload = async (e) => {
  //   try {
  //     let files = e.target.files;
  //     if (files.length > 0) {
  //       dispatch(handlePaperLoading(true));

  //       let formdata = new FormData();
  //       formdata.append("email", localStorage.getItem("email"));
  //       formdata.append("project_id", localStorage.getItem("projectId"));

  //       if (files.length > 0) {
  //         files.forEach((item) => {
  //           formdata.append("file", item);
  //         });
  //       } else {
  //         formdata.append("file", "");
  //       }
  //       let requestOptions = {
  //         method: "POST",
  //         body: formdata,
  //         redirect: "follow",
  //       };
  //       Toast("info", "Uploading files & fetching references, please wait...");
  //       fetch(`${baseDomain}/upload`, requestOptions)
  //         .then((response) => response.text())
  //         .then((result) => {
  //           let parsed = JSON.parse(result);
  //           // Check for error in the response
  //           if (parsed.error) {
  //             // Show the error message from the response
  //             Toast("error", parsed.error);
  //             dispatch(handlePaperLoading(false));
  //             return;
  //           }
  //           if (parsed.fileUploaded.length > 0 || parsed.fileError) {
  //             Toast("warning", parsed.fileError);
  //           }
  //           Toast(
  //             "info",
  //             "File(s) uploaded , fetching Reference(s) in background."
  //           );
  //           onSuccess();

  //           if (parsed?.fileUploaded.length > 0) {
  //             const saveEmbedInfo = {
  //               saveEmbeddings: true,
  //               email: localStorage.getItem("email"),
  //               title: localStorage.getItem("projectTitle"),
  //               s3KeysArr: parsed?.fileUploaded,
  //             };
  //             saveEmbed(saveEmbedInfo);
  //             var myHeaders = new Headers();
  //             myHeaders.append("Content-Type", "application/json");
  //             myHeaders.append(
  //               "Access-Control-Allow-Origin",
  //               "http://localhost:3000"
  //             );
  //             myHeaders.append(
  //               "Access-Control-Allow-Origin",
  //               "https://researchpal.co/"
  //             );
  //             myHeaders.append(
  //               "Access-Control-Allow-Methods",
  //               "GET, POST, PUT, DELETE"
  //             );
  //             let info = {
  //               getCitationsAgainstMultiplePapers: true,
  //               email: localStorage.getItem("email"),
  //               title: localStorage.getItem("projectTitle"),
  //               token: localStorage.getItem("token") || "",
  //               s3KeysArr: parsed?.fileUploaded,
  //             };
  //             let request = {
  //               method: "POST",
  //               headers: myHeaders,
  //               body: JSON.stringify(info),
  //               redirect: "follow",
  //             };
  //             fetch(`${baseDomain}/script`, request)
  //               .then((response) => response.text())
  //               .then((result) => {
  //                 dispatch(handlePaperLoading(false));
  //                 setFileInputKey((prev) => !prev);
  //                 let res = JSON.parse(result);
  //                 if (res.data.sourceObj.responseArr) {
  //                   if (res.data.sourceObj.error !== "") {
  //                     Toast("error", res.data.sourceObj.error);
  //                   } else {
  //                     Toast("success", "Reference(s) saved successfully");
  //                   }
  //                   onSuccess();
  //                   dispatch(clearRefs());
  //                 } else {
  //                   Toast("error", res.data.sourceObj.response);
  //                   onSuccess();
  //                 }
  //               });
  //           } else {
  //             dispatch(handlePaperLoading(false));
  //             onSuccess();
  //             let parsed = JSON.parse(result);
  //             Toast("error", parsed.error);
  //           }
  //         })
  //         .catch((error) => {
  //           Toast("error", error);
  //           dispatch(handlePaperLoading(false));
  //         });
  //     }
  //   } catch (err) {
  //     console.log("err:", err);
  //     dispatch(handlePaperLoading(false));
  //   }
  // };

  const handleUpload = async (e) => {
    try {
      let files = e.target.files;
      if (files.length > 0) {
        dispatch(handlePaperLoading(true));

        let formdata = new FormData();
        formdata.append("email", localStorage.getItem("email"));
        formdata.append("project_id", localStorage.getItem("projectId"));

        if (files.length > 0) {
          files.forEach((item) => {
            formdata.append("file", item);
          });
        } else {
          formdata.append("file", "");
        }
        let requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        Toast("info", "Uploading files & fetching references, please wait...");
        fetch(`${baseDomain}/upload`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            let parsed = JSON.parse(result);

            // Always call onSuccess to handle subsequent API calls
            onSuccess();

            if (parsed.error) {
              // Show the error message from the response
              Toast("error", parsed.error);
            }

            // Handle upload result
            if (parsed.fileUploaded.length > 0) {
              // Proceed with saving embeddings
              const saveEmbedInfo = {
                saveEmbeddings: true,
                email: localStorage.getItem("email"),
                title: localStorage.getItem("projectTitle"),
                s3KeysArr: parsed.fileUploaded,
              };
              saveEmbed(saveEmbedInfo);

              // Fetch citations
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append(
                "Access-Control-Allow-Origin",
                "http://localhost:3000"
              );
              myHeaders.append(
                "Access-Control-Allow-Origin",
                "https://researchpal.co/"
              );
              myHeaders.append(
                "Access-Control-Allow-Methods",
                "GET, POST, PUT, DELETE"
              );

              let info = {
                getCitationsAgainstMultiplePapers: true,
                email: localStorage.getItem("email"),
                title: localStorage.getItem("projectTitle"),
                token: localStorage.getItem("token") || "",
                s3KeysArr: parsed.fileUploaded,
              };
              let request = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(info),
                redirect: "follow",
              };
              fetch(`${baseDomain}/script`, request)
                .then((response) => response.text())
                .then((result) => {
                  dispatch(handlePaperLoading(false));
                  setFileInputKey((prev) => !prev);
                  let res = JSON.parse(result);
                  if (res.data.sourceObj.responseArr) {
                    if (res.data.sourceObj.error !== "") {
                      Toast("error", res.data.sourceObj.error);
                    } else {
                      Toast("success", "Reference(s) saved successfully");
                      onSuccess();
                    }
                    dispatch(clearRefs());
                  } else {
                    Toast("error", res.data.sourceObj.response);
                  }
                });
            } else {
              // Handle case when no files were uploaded
              dispatch(handlePaperLoading(false));
              if (parsed.error) {
                Toast("error", parsed.error);
              }
            }
          })
          .catch((error) => {
            Toast("error", error);
            dispatch(handlePaperLoading(false));
          });
      }
    } catch (err) {
      console.log("err:", err);
      dispatch(handlePaperLoading(false));
    }
  };

  // const handleUpload = async (e) => {
  //   try {
  //     let files = e.target.files;
  //     if (files.length > 0) {
  //       dispatch(handlePaperLoading(true));

  //       let formdata = new FormData();
  //       formdata.append("email", localStorage.getItem("email"));
  //       formdata.append("project_id", localStorage.getItem("projectId"));

  //       if (files.length > 0) {
  //         files.forEach((item) => {
  //           formdata.append("file", item);
  //         });
  //       } else {
  //         formdata.append("file", "");
  //       }
  //       let requestOptions = {
  //         method: "POST",
  //         body: formdata,
  //         redirect: "follow",
  //       };
  //       Toast("info", "Uploading files & fetching references, please wait...");
  //       const result = await fetch(`${baseDomain}/upload`, requestOptions);
  //       const parsed = await result.json();

  //       // Check for error in the response
  //       if (parsed.error) {
  //         Toast("error", parsed.error);
  //       }

  //       // Always show the upload success message
  //       if (parsed.fileUploaded.length > 0 || parsed.fileError) {
  //         // Toast("warning", parsed.fileError || "Files uploaded successfully.");
  //       }

  //       // Toast(
  //       //   "info",
  //       //   "File(s) uploaded, fetching Reference(s) in the background."
  //       // );

  //       // Execute additional operations if files are uploaded
  //       if (parsed?.fileUploaded.length > 0) {
  //         const saveEmbedInfo = {
  //           saveEmbeddings: true,
  //           email: localStorage.getItem("email"),
  //           title: localStorage.getItem("projectTitle"),
  //           s3KeysArr: parsed?.fileUploaded,
  //         };
  //         saveEmbed(saveEmbedInfo);

  //         const myHeaders = new Headers({
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "http://localhost:3000",
  //           "Access-Control-Allow-Origin": "https://researchpal.co/",
  //           "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
  //         });

  //         const info = {
  //           getCitationsAgainstMultiplePapers: true,
  //           email: localStorage.getItem("email"),
  //           title: localStorage.getItem("projectTitle"),
  //           token: localStorage.getItem("token") || "",
  //           s3KeysArr: parsed?.fileUploaded,
  //         };

  //         const request = {
  //           method: "POST",
  //           headers: myHeaders,
  //           body: JSON.stringify(info),
  //           redirect: "follow",
  //         };

  //         const citationResult = await fetch(`${baseDomain}/script`, request);
  //         const citationParsed = await citationResult.json();

  //         if (citationParsed.data.sourceObj.responseArr) {
  //           if (citationParsed.data.sourceObj.error !== "") {
  //             Toast("error", citationParsed.data.sourceObj.error);
  //           } else {
  //             Toast("success", "Reference(s) saved successfully");
  //           }
  //         } else {
  //           Toast("error", citationParsed.data.sourceObj.response);
  //         }
  //       }

  //       dispatch(handlePaperLoading(false));
  //       setFileInputKey((prev) => !prev);
  //       onSuccess();
  //       dispatch(clearRefs());
  //     }
  //   } catch (err) {
  //     // Toast("error", err.message || "An error occurred");
  //     dispatch(handlePaperLoading(false));
  //   }
  // };

  // const handleUpload = async (e) => {
  //   try {
  //     let files = e.target.files;
  //     if (files.length > 0) {
  //       dispatch(handlePaperLoading(true));

  //       let formdata = new FormData();
  //       formdata.append("email", localStorage.getItem("email"));
  //       formdata.append("project_id", localStorage.getItem("projectId"));

  //       // Append files to FormData
  //       for (let i = 0; i < files.length; i++) {
  //         formdata.append("file", files[i]);
  //       }

  //       let requestOptions = {
  //         method: "POST",
  //         body: formdata,
  //         redirect: "follow",
  //       };
  //       Toast("info", "Uploading files & fetching references, please wait...");
  //       fetch(`${baseDomain}/upload`, requestOptions)
  //         .then((response) => response.text())
  //         .then((result) => {
  //           let parsed = JSON.parse(result);

  //           if (parsed.error) {
  //             Toast("error", parsed.error);
  //             dispatch(handlePaperLoading(false));
  //             return;
  //           }

  //           const { fileUploaded, fileError } = parsed;
  //           if (fileUploaded.length > 0) {
  //             Toast(
  //               "info",
  //               `${fileUploaded.length} file(s) uploaded successfully.`
  //             );
  //             Toast(
  //               "warning",
  //               "Upload papers limit reached. Please upgrade your subscription for more."
  //             );

  //             // Process successfully uploaded files
  //             const saveEmbedInfo = {
  //               saveEmbeddings: true,
  //               email: localStorage.getItem("email"),
  //               title: localStorage.getItem("projectTitle"),
  //               s3KeysArr: fileUploaded,
  //             };
  //             saveEmbed(saveEmbedInfo);

  //             const myHeaders = new Headers();
  //             myHeaders.append("Content-Type", "application/json");
  //             myHeaders.append(
  //               "Access-Control-Allow-Origin",
  //               "http://localhost:3000"
  //             );
  //             myHeaders.append(
  //               "Access-Control-Allow-Origin",
  //               "https://researchpal.co/"
  //             );
  //             myHeaders.append(
  //               "Access-Control-Allow-Methods",
  //               "GET, POST, PUT, DELETE"
  //             );

  //             let info = {
  //               getCitationsAgainstMultiplePapers: true,
  //               email: localStorage.getItem("email"),
  //               title: localStorage.getItem("projectTitle"),
  //               token: localStorage.getItem("token") || "",
  //               s3KeysArr: fileUploaded,
  //             };

  //             let request = {
  //               method: "POST",
  //               headers: myHeaders,
  //               body: JSON.stringify(info),
  //               redirect: "follow",
  //             };

  //             fetch(`${baseDomain}/script`, request)
  //               .then((response) => response.text())
  //               .then((result) => {
  //                 dispatch(handlePaperLoading(false));
  //                 setFileInputKey((prev) => !prev);
  //                 let res = JSON.parse(result);
  //                 if (res.data.sourceObj.responseArr) {
  //                   if (res.data.sourceObj.error !== "") {
  //                     Toast("error", res.data.sourceObj.error);
  //                   } else {
  //                     Toast("success", "Reference(s) saved successfully");
  //                   }
  //                   onSuccess();
  //                   dispatch(clearRefs());
  //                 } else {
  //                   Toast("error", res.data.sourceObj.response);
  //                   onSuccess();
  //                 }
  //               });

  //             // Fetch PDF data for the uploaded files
  //             fetchPdfData(fileUploaded);
  //           } else {
  //             dispatch(handlePaperLoading(false));
  //             Toast("error", fileError);
  //             onSuccess();
  //           }
  //         })
  //         .catch((error) => {
  //           Toast("error", error);
  //           dispatch(handlePaperLoading(false));
  //         });
  //     }
  //   } catch (err) {
  //     console.log("err:", err);
  //     dispatch(handlePaperLoading(false));
  //   }
  // };

  // // Function to fetch PDF data
  // const fetchPdfData = async (fileKeys) => {
  //   try {
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         researchProjectId: localStorage.getItem("projectId"),
  //         s3KeysArr: fileKeys,
  //       }),
  //     };
  //     const response = await fetch(`${baseDomain}/getPdfData`, requestOptions);
  //     const result = await response.text();
  //     const parsed = JSON.parse(result);
  //     if (parsed.response.status.statusCode === 200) {
  //       // Handle the PDF data here, e.g., update state or dispatch actions
  //     } else {
  //       Toast("error", "Failed to fetch PDF data.");
  //     }
  //   } catch (error) {
  //     Toast("error", "Error fetching PDF data.");
  //   }
  // };

  // const saveEmbed = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Access-Control-Allow-Origin", "http://localhost:3000");
  //   myHeaders.append("Access-Control-Allow-Origin", "https://researchpal.co/");
  //   myHeaders.append("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE");
  //   let info = {
  //     saveEmbeddings: true,
  //     email: localStorage.getItem("email"),
  //     title: localStorage.getItem("projectTitle"),
  //   };
  //   var request = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: JSON.stringify(info),
  //     redirect: "follow",
  //   };
  //   fetch(`${baseDomain}/script`, request)
  //     .then((response) => response.text())
  //     .then((result) => {});
  // };
  const toggle = () => {
    if (!paperLoading) {
      setModal((prev) => !prev);
      onSuccess();
    }
  };

  const saveRefs = async () => {
    try {
      let req = {
        researchProjectId: localStorage.getItem("projectId"),
      };
      if (ref.pdfFile) {
        req.s3Files = [ref.pdfFile];
      }

      req.s3FileReferences = {
        [ref.pdfFile]: ref?.citations,
      };

      setRefLoading(true);
      await saveResearchReferences(req);
      dispatch(clearRefs());
      onSuccess();
      setRefLoading(false);
      setModal(false);
    } catch (err) {
      onSuccess();
      Toast("error", "Error");
    }
  };
  return (
    <Box>
      {paperLoading && (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <CircularProgress size={15} sx={{ color: "#353535" }} />{" "}
          <Typography sx={{ ml: "8px", fontSize: "10px" }}>
            Please Wait
          </Typography>
        </Stack>
      )}
      {!paperLoading && (
        <>
          <Btn
            size="small"
            onClick={handleButtonClick}
            sx={{ height: "32px !important", color: "#14B296 !important" }}
          >
            <CloudUploadIcon
              sx={{ color: "#14B296", fontSize: "14px", mr: 0.8 }}
            />
            Upload
          </Btn>

          <input
            type="file"
            id="upload"
            hidden
            multiple
            ref={fileInputRef}
            key={fileInputKey}
            onChange={handleUpload}
          />
        </>
      )}
      <Dialog open={modal} fullWidth maxWidth="sm">
        <DialogContent>
          <Stack spacing={5}>
            <Typography variant="h6">Paper Reference:</Typography>
            <Box>
              {ref?.citations == "-" && (
                <Typography sx={{ textAlign: "center" }}>
                  No Reference found
                </Typography>
              )}
              {ref?.citations != "-" && (
                <Typography sx={{ textAlign: "center" }}>
                  {ref?.citations}
                </Typography>
              )}
            </Box>
            {refLoading && (
              <Stack alignItems="center" justifyContent="center">
                <CircularProgress size={30} color="success" />
              </Stack>
            )}
            {!refLoading && ref?.citations != "-" && (
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    background: "#ffffff !important",
                    color: "#353535",
                  }}
                  onClick={toggle}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    background: "#353535 !important",
                  }}
                  onClick={saveRefs}
                >
                  Add to References
                </Button>
              </Stack>
            )}
            {!refLoading && ref?.citations == "-" && (
              <Stack direction="row" width="100%" justifyContent="center">
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    background: "#ffffff !important",
                    color: "#353535",
                  }}
                  onClick={toggle}
                >
                  Close
                </Button>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
      {/* {loading && <CircularProgress color="inherit" />} */}
    </Box>
  );
}

export default UploadFile;
