import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useRef, useState } from "react";
import { Ratings } from "../../utils/Ratings";
import { SubmitDialogue } from "../../assets/svg/LoopLogo";
import moment from "moment";
import { saveFeed } from "../../apiservice";
import { toast } from "react-toastify";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
const FeedbackDialogue = ({ openDialogue, onClose }) => {
  let feedback = useRef();

  const [state, setState] = useState({
    ratings: 0,
  });
  const getRatings = (rt) => {
    setState({ ...state, ratings: rt });
  };
  const SubmitFeedback = async () => {
    if (!state.ratings) {
      toast.error("Please provide a rating.");
      return;
    }

    if (!feedback.current.value.trim()) {
      toast.error("Please provide feedback.");
      return;
    }

    let data = {
      email: localStorage.getItem("email"),
      feedback: {
        rating: state.ratings,
        date: moment().format("DD-MM-YYYY"),
        message: feedback.current.value,
      },
    };

    let res = await saveFeed(data);
    if (res.feedbackId) {
      toast.success("Thank you for your feedback", {
        icon: <ThumbUpIcon />,
      });
      onClose("openFeedBackDialogue");
    }
  };

  return (
    <Dialog
      onClose={() => onClose("openFeedBackDialogue")}
      open={openDialogue}
      sx={{
        padding: "0px !important",
        "& .MuiDialog-paper": {
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        fontSize={16}
        fontWeight={600}
        fontFamily={"Raleway"}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center", // Align to the left
          textAlign: "center",
        }}
      >
        {/* <h1 style={{ color: "#000", fontWeight: "bold" }}> */}
        <h1 style={{ color: "#1E4F6B", fontWeight: "bold" }}>
          Give Your Valuable Feedback
        </h1>
      </DialogTitle>

      <DialogContent dividers>
        {" "}
        <div className=" grid place-items-center ">
          <div className="flex ">
            <div className="flex ">
              <div>
                <div className="grid place-items-center mb-3">
                  <Ratings getRatings={(rt) => getRatings(rt)} />
                </div>
                <textarea
                  style={{ width: "450px", border: "1px solid #000" }}
                  className="px-2 mb-3  justify-center mb-1 text-sm text-black"
                  rows={8}
                  ref={feedback}
                  placeholder="Your feedback ..."
                />
                <div className="submit grid place-items-center">
                  <button onClick={SubmitFeedback}>
                    <SubmitDialogue />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FeedbackDialogue;
