import React, { useState, useEffect } from "react";
import { Container, Typography, Box, styled, Grid } from "@mui/material";
import termsData, {
  BeforeTOC,
  tableOfContents,
  tableofcontentPoints,
} from "./terms";
import LandingHeader from "../../../layout/header";
import { useLocation } from "react-router-dom";
import Login from "../../authentication/login";
import ForgotPassword from "../../Admin/ForgotPassword";
import Signup from "../../authentication/sign up";
import Footer from "../../../layout/footer";
const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "700px",
  padding: "64px 202px 0 202px",
  marginInline: "10px",
  // marginTop: "10vh",
  marginBottom: "10vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "38px",
    padding: "64px 10px 0 10px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "38px",
    padding: "64px 10px 0 10px",
  },
}));

const Text = styled(Typography)({
  fontSize: "14px",
  fontFamily: "sans-serif",
});
const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "sans-serif",
  fontWeight: 600,
  fontSize: "40px",
  color: "#353535",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));
const SubTitles = styled(Typography)(({ theme }) => ({
  fontFamily: "sans-serif",
  fontWeight: 500,
  fontSize: "20px",
  color: "#353535",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));
const TermsAndConditions = () => {
  const location = useLocation();

  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
    contactUsModal: false,
  });
  const toggle = (name) => {
    if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };

  return (
    <Box>
      <Box
        sx={{
          zIndex: 1,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <LandingHeader onClick={(e) => toggle(e)} />
      </Box>
      <RootStyle>
        <Title>Terms of Service</Title>
        <Text>Last updated: 16 May, 2024</Text>
        <Text mb={2}>AGREEMENT TO OUR LEGAL TERMS</Text>
        {BeforeTOC.map((item) => (
          <Text>{item.content}</Text>
        ))}
        {tableOfContents.map((item) => (
          <Text mb={2}>{item.content}</Text>
        ))}
        {tableofcontentPoints.map((item) => (
          <>
            <SubTitles mb={2}>{item.heading}</SubTitles>
            <Text mb={2}>{item.content}</Text>
          </>
        ))}
      </RootStyle>
      <Footer />

      {state.loginModal && (
        <Login open={state.loginModal} onClose={(e) => toggle(e)} />
      )}
      {state.forgotModal && (
        <ForgotPassword
          open={state.forgotModal}
          onClose={() => toggle("forgotModal")}
        />
      )}
      {state.signupModal && (
        <Signup
          open={state.signupModal}
          onClose={() => toggle("signupModal")}
        />
      )}
    </Box>
  );
};

export default TermsAndConditions;
