import React, { useEffect, useState } from "react";
import { Delete, Edit } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControl, IconButton } from "@mui/material";
import { InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EditReferences, updateResearchProject } from "../../apiservice";
import RefsImg from "../../assets/images/references.svg";
import BtnClear from "../../components/BtnClear";
import CopyButton from "../../components/CopyButton";
import DeleteDialog from "../../components/DeleteDialog";
import SearchField from "../../components/SearchField";
import TailSpinLoader from "../../components/TailSpinLoader";
import Toast from "../../components/Toast";
import Repo from "../../repo/Repo";
import { getRefs } from "./refsSlice";
import ManualRefrenceDialogue from "./ManualRefrences/ManualRefrenceDialogue";
import ReactGA from "../../Analytics";
import { useLocation, useNavigate } from "react-router-dom";
import EditReferenceDialog from "./EditReferenceDialog";

const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "10px",
  fontWeight: 500,
  color: "#353535CC",
});

const RootStyle = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "#FFFFFF",
  minHeight: "100vh",
  padding: "16px 32px",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 3.57397px 40.2071px rgba(0, 0, 0, 0.08)",
}));
const CustomMenue = styled(MenuItem)({
  fontFamily: "Raleway",
});

function References({ data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { refFormats, loading } = useSelector((state) => state.refs);
  console.log(
    "RefFormats:",
    refFormats,
    " and",
    Object.keys(refFormats).length
  );
  console.log("Type of refFormat is:", typeof refFormats);
  const { user } = useSelector((state) => state.user);

  const [state, setState] = useState({
    loading: false,
    checked: [],
    delete: false,
    filesData: [],
    selected: [],
    selectedDOIs: {},
  });
  const [openRefrenceDialogue, setOpenRefrenceDialogue] = useState(false);
  const [query, setQuery] = useState("");
  const [deleteAll, setDeleteAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editReference, setEditReference] = useState(null);
  const [editRefLoading, setRefLoading] = useState(false);
  const [editKey, setEditKey] = useState(null);
  const [format, setFormat] = useState(
    localStorage.getItem("selectedFormat") || "apa"
  );
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "References",
    });
  }, []);
  useEffect(() => {
    if (!refFormats) {
      dispatch(getRefs(localStorage.getItem("projectId")));
    }
  }, []);
  // useEffect(() => {
  //   if (!refFormats || Object.keys(refFormats).length === 0) {
  //     dispatch(getRefs(localStorage.getItem("projectId")));
  //   }
  // }, [refFormats, dispatch]);

  const toggle = () => {
    setOpen((prev) => !prev);
    setDeleteAll(false);
  };
  const DeleteAllToggle = () => {
    setDeleteAll((prev) => !prev);
  };

  const clearRef = async () => {
    const info = {
      _id: localStorage.getItem("projectId"),
      references: {},
      referenceFormats: {},
    };
    let res = await updateResearchProject(info);
    if (res.researchProjectId) {
      Toast("success", "All References deleted");
      dispatch(getRefs(localStorage.getItem("projectId")));
      setDeleteAll(false);
    }
  };

  const handleInput = (e) => {
    const { value } = e.target;
    setFormat(value);
    localStorage.setItem("selectedFormat", value);
  };
  const handleQuery = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const formats = () => {
    let arr = [];
    for (const [key, value] of Object.entries(refFormats)) {
      if (value[format]) {
        arr.push(value[format]);
      }
    }

    let filtered = arr.filter((i) =>
      i.toString().toLowerCase().includes(query)
    );

    return filtered;
  };

  console.log("Filtered", formats());

  const refKeys = (arg) => {
    let item = null;
    for (const [key, value] of Object.entries(refFormats)) {
      if (value[format] == arg) {
        item = key;
      }
    }
    return item;
  };
  //q:what does refKeys do
  //a:
  const handleClose = () => {
    setOpenRefrenceDialogue(false);
  };
  const handleOpen = () => {
    setOpenRefrenceDialogue(true);
  };
  const handleSelect = (val) => {
    let key = refKeys(val);
    if (state.selectedDOIs[key] !== undefined) {
      let copy = JSON.parse(JSON.stringify(state.selectedDOIs));
      delete copy[key];
      setState((prev) => ({
        ...prev,
        selectedDOIs: copy,
        selected: state.selected.filter((item) => item !== key),
      }));
    } else {
      let copy = JSON.parse(JSON.stringify(state.selectedDOIs));
      copy[key] = key;
      //q:filter
      setState((prev) => ({
        ...prev,
        selectedDOIs: copy,
        selected: [...state.selected, key],
      }));

      //
    }
  };

  const deleteRefs = async () => {
    console.log("state.selected:", state.selected);
    try {
      setOpen((prev) => !prev);
      setState((prev) => ({ ...prev, loading: true }));

      let res = await Repo.research({
        request: {
          method: "deleteReferences",
          data: {
            email: localStorage.getItem("email"),
            title: localStorage.getItem("projectTitle"),
            referenceIds: state.selected,
            pdfAbstractIds: [],
          },
        },
      });
      if (res?.data?.response?.data?.id) {
        Toast("success", "References deleted successfully");
        dispatch(getRefs(localStorage.getItem("projectId")));
        setState((prev) => ({
          ...prev,
          loading: false,
          selected: [],
          selectedDOIs: {},
        }));
      } else {
        Toast("error", "Error");
      }
    } catch (err) {
      console.log("err:", err);
      setState((prev) => ({ ...prev, loading: true }));
    }
  };
  //Edit functionality
  const handleEdit = (reference, key) => {
    setOpenEditDialog(true);
    setEditReference(reference);
    setEditKey(key);
  };

  const handleEditSubmit = async (editedReference) => {
    console.log("editedReference:", editedReference);
    //get the key of the reference to be edited

    //how i will get the harvard key of the reference to be edited
    //q:how i will get the harvard key of the reference to be edited
    const key = refKeys(editReference);
    //q:but this gives null

    console.log("checkReference(editReference):", key);
    setRefLoading(true);
    try {
      const res = await EditReferences({
        researchProjectId: localStorage.getItem("projectId"),
        reference: editedReference,
        key: editKey,
      });

      if (res.researchProjectId) {
        Toast("success", "Reference updated successfully");
        setRefLoading(false);
        setOpenEditDialog(false);

        dispatch(getRefs(localStorage.getItem("projectId")));
      } else {
        Toast("error", "Error updating reference");
        setRefLoading(false);
      }
    } catch (err) {
      console.log("Error updating reference:", err);
      setRefLoading(false);
    }
  };
  const handleDelete = () => {
    setDeleteAll(true);
    setOpen(false);
  };

  return (
    <RootStyle>
      <SearchField
        place="Search references ..."
        val={query}
        onChange={handleQuery}
      />
      {!refFormats && (
        <Stack
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginTop: "16px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{
              background: "#F2F2F2 !important",
              textTransform: "none",
              fontFamily: "Raleway",
              fontSize: "12px",
              fontWeight: 700,
              color: "#868686",
              boxShadow: "none !important",
            }}
            onClick={handleOpen}
          >
            Add Manual Reference
          </Button>
        </Stack>
      )}
      <Box>
        {loading && (
          <Box sx={{ mt: 5 }}>
            <TailSpinLoader />
          </Box>
        )}

        {!loading && !refFormats && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "75vh",
            }}
          >
            <img src={RefsImg} alt="" />
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: "16px",
                fontWeight: 500,
                color: "#4E4E4E",
                my: 1,
              }}
            >
              No references found
            </Typography>
          </Box>
        )}

        {!loading && refFormats && (
          <Box
            sx={{
              my: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "fit-content" },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text sx={{ my: 1.6, fontSize: "12px", fontWeight: 600 }}>
                References :
              </Text>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ mx: 1 }}>
                  <BtnClear onClick={handleDelete} />
                </Box>
                {state.selected.length > 0 && (
                  <Delete
                    sx={{ color: "#E25050", cursor: "pointer" }}
                    onClick={toggle}
                  />
                )}
              </Box>
            </Box>
            <Button
              variant="contained"
              size="small"
              sx={{
                background: "#F2F2F2 !important",
                textTransform: "none",
                fontFamily: "Raleway",
                fontSize: "12px",
                fontWeight: 700,
                color: "#868686",
                boxShadow: "none !important",
              }}
              onClick={handleOpen}
            >
              Add Manual Reference
            </Button>
            <FormControl
              sx={{ minWidth: { xs: "120px", md: "200px" } }}
              size="small"
            >
              <InputLabel
                sx={{
                  fontFamily: "Raleway",
                }}
              >
                Select Format
              </InputLabel>
              <Select
                size="small"
                value={format}
                label="Select Format"
                onChange={handleInput}
                sx={{
                  fontFamily: "Raleway",
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                    },
                  },
                }}
              >
                <CustomMenue value="apa">APA</CustomMenue>
                <CustomMenue value="mla">MLA</CustomMenue>
                <CustomMenue value="chicago">Chicago</CustomMenue>
                <CustomMenue value="harvard">Harvard</CustomMenue>
              </Select>
            </FormControl>
          </Box>
        )}
        <Box sx={{ maxHeight: "75vh", overflow: "auto" }}>
          {refFormats &&
            formats()?.map((item, i) => (
              <Box
                sx={{ display: "flex", alignItems: "start", pr: "8px" }}
                key={i}
              >
                <Checkbox
                  sx={{}}
                  size="small"
                  disableRipple
                  checked={state.selectedDOIs[refKeys(item)] ? true : false}
                  onChange={() => handleSelect(item)}
                />
                <Box
                  sx={{
                    mb: 1.6,
                    background: "#F9F9F9",
                    padding: "8px",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      sx={{
                        fontSize: "14px",
                        mr: 5,
                        // filter:
                        //   i >= 2 && user.subscription.planName !== "standard"
                        //     ? "blur(5px)"
                        //     : "none",

                        // userSelect:
                        //   user.subscription.planName !== "standard"
                        //     ? "none"
                        //     : "auto",
                      }}
                    >
                      <span style={{ fontWeight: "600", fontSize: "18px" }}>
                        {i + 1}.
                      </span>
                      {item}
                    </Text>
                    <Stack
                      direction="row"
                      justifyContent={"center"}
                      alignItems={"center"}
                      spacing={1}
                    >
                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={{
                          borderRadius: "0px",
                        }}
                        onClick={() =>
                          handleEdit(
                            refFormats[refKeys(item)]["harvard"],
                            //send key of the reference to be edited
                            refKeys(item)
                          )
                        }
                      >
                        <Edit fontSize="inherit" />
                      </IconButton>
                      <CopyButton text={item} file="refs" />
                    </Stack>
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
      {/* {refFormats &&
        formats().length > 2 &&
        user.subscription.planName !== "standard" && (
          <Text
            sx={{
              fontSize: "14px",
              color: "black",
              fontWeight: "bold", // Text color for overlay
              textAlign: "center",
            }}
          >
            Subscribe now to get full access : &nbsp;
            <Button
              size="small"
              sx={{
                textTransform: "none",
                background: "#353535 !important",
                color: "white",
                fontFamily: "Raleway",
                fontWeight: 600,
                fontSize: "12px",
              }}
              onClick={() => navigate("/subscription")}
            >
              Subscribe Now{" "}
            </Button>
          </Text>
        )} */}
      {open && <DeleteDialog onSuccess={deleteRefs} onClose={toggle} />}
      {deleteAll && (
        <DeleteDialog onSuccess={clearRef} onClose={DeleteAllToggle} />
      )}
      {openRefrenceDialogue && (
        <ManualRefrenceDialogue
          handleClose={handleClose}
          open={openRefrenceDialogue}
          data={data}
        />
      )}

      {openEditDialog && (
        <EditReferenceDialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          reference={editReference}
          onSubmit={handleEditSubmit}
          editLoading={editRefLoading}
        />
      )}
    </RootStyle>
  );
}

export default References;
