// class API {
//   static async uploadImage(e) {
//     await new Promise(r => setTimeout(r, 500));
//     return e ? URL.createObjectURL(e) : 'https://via.placeholder.com/150';
//   }
// }

// export { API };
// export default API;
const AWS = require("aws-sdk");

// Configure AWS SDK with your credentials and the region of your S3 bucket
AWS.config.update({
  // accessKeyId: "AKIAWC2A6LYAWKHRIGXW",
  // secretAccessKey: "kNKew1crEOdHOBoLIOI3kOj8TROCEuaa5TxSI+de",
  accessKeyId: "AKIAWC2A6LYAVDRGNS6U",
  secretAccessKey: "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",
  region: "us-west-2",
});

class API {
  static async uploadImage(e) {
    // Simulate image upload delay
    await new Promise((r) => setTimeout(r, 500));

    // Create an instance of the S3 service
    const s3 = new AWS.S3();

    // Define parameters for uploading the image to S3
    const params = {
      Bucket: "veracious-documents",
      Key: e.name, // The file name to upload
      Body: e,
    };

    try {
      // Upload the image to S3
      const data = await s3.upload(params).promise();

      // Return the URL of the uploaded image
      return data.Location;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      // Handle error
      return null; // Return null in case of error
    }
  }
}

export { API };
export default API;
