import React, { useState } from "react";
import { Box, Card, CardContent, CardMedia, styled } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import ProjectLibrary from "../../../assets/images/NewImages/project-library-illustration.png";
import LiteratureReview from "../../../assets/images/NewImages/literature-review-illustration.png";
import TextEditor from "../../../assets/images/NewImages/text-editor-illustration.png";
import SearchImage from "../../../assets/images/NewImages/search-illustration.png";
import AskResearch from "../../../assets/images/NewImages/ask-researchpal-illustration.png";
import TextCitation from "../../../assets/images/NewImages/text-citations-illustration.png";
import { TypeAnimation } from "react-type-animation";
import { useLocation } from "react-router-dom";
import Login from "../../authentication/login";
import ForgotPassword from "../../Admin/ForgotPassword";
import Signup from "../../authentication/sign up";
import LandingHeader from "../../../layout/header";
import Footer from "../../../layout/footer";

const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "700px",
  background: "#fff",
  // marginTop: "10vh",
  padding: "0 102px 64px 102px",
  [theme.breakpoints.down("md")]: {
    padding: "0 10px 64px 10px",
  },
}));
const CustomCard = styled(Card)({
  margin: "0 auto",
  maxWidth: "334px",
  padding: "8px",
  borderRadius: "16px",
  height: "400px",
  boxShadow: "0px 0px 24.35796356201172px 0px rgba(0, 0, 0, 0.08)",
  backdropFilter: "blur(12.17898178100586px)",
});
const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "22px",
  marginBottom: "8px",
});
const CardText = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "16px",
  //justify text to justified
  lineHeight: "24px",
});

function Features() {
  const location = useLocation();

  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
    contactUsModal: false,
  });
  const toggle = (name) => {
    if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };
  return (
    <Box>
      <Box
        sx={{
          zIndex: 1,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <LandingHeader onClick={(e) => toggle(e)} />
      </Box>
      <RootStyle>
        <Typography
          sx={{
            color: "#14224B",
            fontWeight: 400,
            fontFamily: "Righteous",
            fontSize: "32px",
            textAlign: "center",
            pt: 6.4,
          }}
        >
          Augmenting Human Intelligence
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2.4 }}>
          <TypeAnimation
            sequence={[
              "For Students ...",
              1000,
              "For Researchers ...",
              1000,
              "For Industry Professionals ...",
              1500,
              "For Knowledge Workers ...",
              1500,
            ]}
            wrapper="span"
            speed={50}
            style={{
              fontSize: "2em",
              display: "inline-block",
              color: "#15A68F",
              fontWeight: 500,
              fontFamily: "Raleway",
              fontSize: "24px",
            }}
            repeat={Infinity}
          />
        </Box>
        <Grid container spacing={5.6}>
          <Grid item md={4} xs={12} sx={{ mt: { xs: 0, md: "7%" } }}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={LiteratureReview}
                title="preview"
              />
              <CardContent>
                <Title>Literature Review</Title>
                <CardText>
                  Generate a Literature Review for your research question in
                  seconds with authentic citations from credible research
                  sources.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={AskResearch}
                title="preview"
              />
              <CardContent>
                <Title>Reference Generator</Title>
                <CardText>
                  Generate references in any format by adding the title of your
                  research paper or uploading a PDF.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>

          <Grid item md={4} xs={12} sx={{ mt: { xs: 0, md: "7%" } }}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={TextEditor}
                title="preview"
              />
              <CardContent>
                <Title>Rewrite in Any Tone </Title>
                <CardText>
                  Rewrite your paper in any tone and ask ResearchPal to increase
                  or decrease the length of your text in different languages.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>
        </Grid>
        <Grid container spacing={5} sx={{ mt: { xs: 0, md: "1%" } }}>
          <Grid item md={4} xs={12} sx={{ mt: { xs: 0, md: "7%" } }}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={TextCitation}
                title="preview"
              />
              <CardContent>
                <Title>In-Text Citations</Title>
                <CardText>
                  User ResearchPal to find authentic citations from credible
                  journals for your claims within seconds.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={SearchImage}
                title="preview"
              />
              <CardContent>
                <Title>Search</Title>
                <CardText>
                  Optimise your literature search by instantly saving relevant
                  papers references and their abstracts. Find relevant papers
                  with our domain-specific Search.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>

          <Grid item md={4} xs={12} sx={{ mt: { xs: 0, md: "7%" } }}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={ProjectLibrary}
                title="preview"
              />
              <CardContent>
                <Title>Paper Insights</Title>
                <CardText>
                  Analyse research papers at superhuman speed. Automate
                  summarizing papers, extracting methodology and contributions
                  or synthesizing your findings.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>
        </Grid>
        {state.loginModal && (
          <Login open={state.loginModal} onClose={(e) => toggle(e)} />
        )}
        {state.forgotModal && (
          <ForgotPassword
            open={state.forgotModal}
            onClose={() => toggle("forgotModal")}
          />
        )}
        {state.signupModal && (
          <Signup
            open={state.signupModal}
            onClose={() => toggle("signupModal")}
          />
        )}
      </RootStyle>
      <Footer />
    </Box>
  );
}

export default Features;
