import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { Card, Typography, styled } from "@mui/material";
import LeftFrame from "../../assets/images/NewImages/LeftFrame.svg";
import RightFrame from "../../assets/images/NewImages/RightFrame.svg";
import colorBg from "../../assets/images/NewImages/colorBg.png";
import Signup from "../authentication/sign up";
import ForgotPassword from "../Admin/ForgotPassword";
import Login from "../authentication/login";

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Righteous",
  fontWeight: 400,
  fontSize: "40px",
  color: "#FFFFFF",
  [theme.breakpoints.down("md")]: {
    fontSize: "35px",
    textAlign: "center",
  },
}));
const SubTitle = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "18px",
  color: "#FFFFFF",
  textAlign: "center",
});

const ImgBox = styled(Box)({
  height: "272px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  position: "absolute",
});

const RootStyle = styled(Card)({
  background:
    "linear-gradient(90deg, rgba(86, 113, 241, 1) 0%, rgba(23, 206, 173, 1) 100%)",
  height: "272px",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: 0,
  marginBottom: "20px",
});

const JoinUS = ({ onSuccess }) => {
  const [state, setState] = useState({
    loginModal: false,
    forgotModal: false,
    signupModal: false,
  });

  const toggle = (name) => {
    if (name === "faqs" || name === "pricing") {
      onSuccess(name);
    } else if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };

  return (
    <RootStyle>
      <ImgBox>
        <img src={LeftFrame} alt="start" />
        <img src={RightFrame} alt="last" />
      </ImgBox>
      <Box
        sx={{
          width: "100%",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${colorBg})`,
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Title sx={{ mb: 1.6 }}>Join Us</Title>
          <SubTitle sx={{ mb: 3.2 }}>
            Start your first research paper within seconds !
          </SubTitle>
          <Button
            variant="contained"
            sx={{
              height: "45px",
              background: "white !important",
              textTransform: "none",
            }}
          >
            <SubTitle
              sx={{
                p: { xs: "10px 20px", md: "24px 40px" },
                fontWeight: 600,
                color: "#353535",
              }}
              onClick={() => toggle("loginModal")}
            >
              Start writing now
            </SubTitle>
          </Button>
        </Box>
      </Box>
      {state.loginModal && (
        <Login open={state.loginModal} onClose={(e) => toggle(e)} />
      )}
      {state.forgotModal && (
        <ForgotPassword
          open={state.forgotModal}
          onClose={() => toggle("forgotModal")}
        />
      )}
      {state.signupModal && (
        <Signup
          open={state.signupModal}
          onClose={() => toggle("signupModal")}
        />
      )}
    </RootStyle>
  );
};

export default JoinUS;
